import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subscription } from 'rxjs/index';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd';

@Injectable()
export class HttpService {
  constructor(private http: HttpClient, private router: Router, private message: NzMessageService) {
    this.subs = new Array<Subscription>();
  }
  private subs: Subscription[];
  private header = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Origin', '*');
  postParamObj = {
    withCredentials: true,
    headers: this.header
  };
  getParamObj = {
    withCredentials: true,
  };

  // 封装统一的 http 请求方法
  get(url: string,
      next?: (value: Response) => void,
      error?: (error: any) => void,
      complete?: () => void): void {
    if (url.indexOf('?') > -1) {
      // 有参数
      url += `&dateStamp=` +  + new Date().getTime().toString();
    } else {
      // 无参数
      url += `?dateStamp=` + new Date().getTime().toString();
    }
    this.subs.push(this.http.get(`${url}`, this.getParamObj).subscribe(next, error, complete));
  }

  post(url: string,
       data: any,
       next?: (value: Response) => void,
       error?: (error: any) => void,
       complete?: () => void): void {
    if (url.indexOf('?') > -1) {
      // 有参数
      url += `&dateStamp=` + new Date().getTime().toString();
    } else {
      // 无参数
      url += `?dateStamp=` + new Date().getTime().toString();
    }

    this.subs.push(this.http.post(url, data, this.postParamObj).subscribe(next, error, complete));
  }

  // token 失效处理函数
  handleToken = (value) => {
    if (value && value.code && value.code === 2) {
      return false;
    } else {
      return true;
    }
  }

  // 跳转登录页面
  toLoginPage(time) {
    window.setTimeout(() => {
      this.router.navigate(['/extra/login']);
    }, time);
  }

  // 复制属性
  public cloneProp(ob, nOb) {
    const car = new nOb();
    for (const prop in ob) {
      if (ob[prop] || ob[prop] === 0) {
        car[prop] = ob[prop];
      } else {
        car[prop] = '';
      }
    }
    return car;
  }
}
