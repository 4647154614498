import {Component, OnInit, ViewChild} from '@angular/core';
import {fadeIn} from '../../animation/fade-in';
import {ApiService} from '../../service/api';
import {NzMessageService, NzModalService} from 'ng-zorro-antd';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CheckService} from '../../service/check';

@Component({
  selector: 'app-gateway',
  templateUrl: './gateway.component.html',
  styles: [`
    .btnPart {
      padding: 10px;
    }
  `],
  animations: [fadeIn]
})
export class GatewayComponent implements OnInit {

  data = [];
  query: any = {
    model: 0
  };
  isLoading = false;
  isAllCheck = false;
  isIndeterminate = false;
  listOfBind = [{text: '已绑定', value: 1}, {text: '未绑定', value: 0}];
  isAddConV = false;
  isMultiAddConV = false;
  isUpdate = false;
  isBindConV = false;
  isDivideConV = false;
  isMulti = false;
  canbind = false;
  canUnbind = false;
  isCheckedOne = false;
  req: any = {};
  companys = [];
  influxdbs = [];
  selectedGateways = [];
  canBindGateways = [];
  req3: any = {};
  scenes = [{sceneNo: '123', sceneName: 'qq'}];
  req4: any = {};
  try = {};
  page = 1;
  rows = 10;
  total = 0;
  @ViewChild('influxdb') influx;

  influxDBTableList = [];
  companyInfluxDBTableList = [];

  constructor(private api: ApiService, private message: NzMessageService, private router: Router,
              private modalService: NzModalService, private translate: TranslateService,
              private ck: CheckService) {
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.getData();
    this.getCompanys();
    this.getAllInfluxDB();
  }

  next = (data) => {
    if (data && data.rows && data.rows.constructor === Array) {
      this.canBindGateways =  data.rows.filter(one => !one.pDeviceNo);
      this.data = data.rows;
      this.total = data.total;
      this.oneCheck(-1);
    } else if (data.code === 1) {
      this.message.success(data && data.message ? data.message : '操作成功');
      this.isAddConV = false;
      this.isMultiAddConV = false;
      this.isBindConV = false;
      this.isDivideConV = false;
      this.search();
    } else if (data.code === 0) {
      this.message.error(data && data.message ? data.message : '操作失败');
    } else {
      this.message.error(data && data.message ? data.message : '获取数据失败');
    }
    this.isLoading = false;
  }

  getData() {
    this.isLoading = true;
    this.api.device.getAll(this.page, this.rows, [{model: 0}, this.next]);
  }

  search() {
    this.isLoading = true;
    this.api.device.getByTerm(this.page, this.rows, [this.query, this.next]);
  }

  getCompanys() {
    this.isLoading = true;
    this.api.company.getAll(['', (data) => {
      if (data && data.constructor === Array) {
        this.companys = data;
      } else {
        this.message.error(data && data.message ? data.message : '获取数据失败');
      }
      this.isLoading = false;
    }]);
  }

  companySelected() {
    // if (!this.req3.device.companyNo) {
    //   this.message.error('该网关并无所属公司');
    //   return;
    // }
    // this.isLoading = true;
    // this.api.influxdbTables.getByCompanyNo([{companyNo: this.req3.device.companyNo}, (data) => {
    //   if (data && data.constructor === Array) {
    //     this.influxdbs = data;
    //   } else {
    //     this.message.error(data && data.message ? data.message : '获取数据失败');
    //   }
    //   this.isLoading = false;
    // }]);
  }

  onSearch() {
    this.page = 1;
    this.search();
  }

  pageChange() {
    this.search();
  }

  onGetAll() {
    this.page = 1;
    this.query = {
      model: 0
    };
    this.listOfBind = [{text: '已绑定', value: 1}, {text: '未绑定', value: 0}];
    this.getData();
  }

  onInput(e) {
    this.selectedGateways = this.canBindGateways.filter(one => one.name.indexOf(e) > -1 || one.sn.indexOf(e) > -1);
  }

  // 复选框的方法
  initCheckArray(b) {
    if (b) {
      this.isCheckedOne = true;
    }
    this.data.map((one, index) => {one.checked = b; one.cI = b ? index : null; });
  }

  checkAll(e) {
    this.isIndeterminate = false;
    this.initCheckArray(e);
    this.checkCanBind();
  }

  oneCheck(i) {
    this.isCheckedOne = true;
    this.isIndeterminate = false;
    this.isAllCheck = false;
    const sum = this.data.filter((one) => one.checked).length;
    if (sum === this.data.length) {
      this.isAllCheck = true;
    } else if (sum === 0) {
      this.isCheckedOne = false;
      this.canbind = false;
      this.canUnbind = false;
      this.data.map(one => one.cI = null);
    } else {
      this.isIndeterminate = true;
      // 如果这个刚刚点击的是被选中, 那么这个cI(check的序列号index)直接赋值就行
      if (i >= 0 && this.data[i].checked) {
        this.data[i].cI = sum - 1;
        // 如果这个刚刚点击的是取消选中,那么需要将这个的序列号清空, 后面所有的序列号都-1
      } else if (!this.data[i].checked) {
        this.data.map(one => one.cI = one.cI > this.data[i].cI ? one.cI - 1 : one.cI);
      }
    }
    this.checkCanBind();
  }

  checkCanBind() {
    const obj = this.data.filter(one => one.checked);
    if (obj.length === 0) {
      this.canbind = false;
      this.canUnbind = false;
    } else {
      const flag: boolean = !!obj[0].pDeviceNo;
      if (obj.filter(one => (!!one.pDeviceNo) === flag).length === obj.length) {
        this.canbind = !flag;
        this.canUnbind = flag;
      } else {
        this.canbind = false;
        this.canUnbind = false;
      }
    }
  }

  // 筛选
  filter(e) {
    this.query.isBind = e;
    this.search();
  }

  // 跳转详情
  toSee(e) {
    this.router.navigate(['/app/deviceCenter/gatewayMgr/gatewaySee'],
      {queryParams: {deviceNo: e.deviceNo, name: e.name}});
  }

  // 单个的方法
  add() {
    this.req = {};
    this.isMulti = false;
    this.isUpdate = false;
    this.isAddConV = true;
    this.try = {
      trans: {name: 'gatewayName', sn: 'gatewaySN', companyNo: 'selectCompany'},
      field: ['name', 'sn', 'companyNo']
    };
  }

  bind(one) {
    this.isMulti = false;
    this.selectedGateways = [];
    this.req3 = {
      address: '',
      deviceNo: one.deviceNo,
      influxdbDatabaseNo: one.influxdbDatabaseNo,
      pDeviceNo: ''
    };
    this.try = {
      trans: {address: 'bindTongxinAddr'},
      field: ['address', 'deviceNo'],
      num: ['address']
    };
    this.isBindConV = true;
  }

  unbind(one) {
    this.modalService.confirm({
      nzTitle  : '<i> </i>',
      nzContent: '<b>' + this.translate.instant('confirmUnbindThisRecord') + '</b>',
      nzOnOk   : () => this.unbindOne(one)
    });
  }

  unbindOne(one) {
    this.isLoading = true;
    this.api.device.unBindDevice([{deviceNo: one.deviceNo}, this.next]);
  }

  update(one) {
    this.isMulti = false;
    this.req = {...one};
    this.getInfluxDBByCompany(one.companyNo);
    this.try = {
      trans: {name: 'gatewayName', sn: 'gatewaySN', companyNo: 'selectCompany'},
      field: ['name', 'sn', 'companyNo', 'deviceNo']
    };
    this.isUpdate = true;
    this.isAddConV = true;
  }

  divide(one) {
    this.isMulti = false;
    this.req4 = {...one};
    this.getInfluxDBByCompany(one.companyNo);
    this.try = {
      trans: {companyNo: 'selectCompany', influxdbDatabaseNo: 'selectCompanyInfluxDBTable'},
      field: ['companyNo', 'deviceNo', 'influxdbDatabaseNo']};
    this.isDivideConV = true;
  }

  cancel(v) {
    this[v] = false;
  }

  save(v) {
    this.isLoading = true;
    if (!this.ck.formCheck(this[v], this.try)) {
      this.isLoading = false;
      return;
    }
    // 单个新增
    if (!this.isMulti && this.isAddConV && !this.isUpdate) {
      this.api.device.addGateway([this.req, this.next]);
      // 单个编辑
    } else if (!this.isMulti && this.isAddConV && this.isUpdate) {
      this.api.device.updateGateway([this.req, this.next]);
      // 单个分配
    } else if (!this.isMulti && this.isDivideConV) {
      this.api.device.updateCompany([this.req4, this.next]);
      // 单个绑定
    } else if (!this.isMulti && this.isBindConV) {
      // this.req3.device = null;
      this.req3.pDeviceNo = this.req3.device.deviceNo;
      this.api.device.gatewayBindGateway([this.req3, this.next]);
      // 批量新增
    } else if (this.isMulti && this.isMultiAddConV) {
      this.api.device.batchAddGateway([this.req, this.next]);
      // 批量绑定
    } else if (this.isMulti && this.isBindConV) {
      this.req3.bindDeviceAddressVos = [];
      this.req3.pDeviceNo = this.req3.device.deviceNo;
      this.req3.device = null;

      this.data.map((one) => {
        if (one.checked) {
          this.req3.bindDeviceAddressVos.push({deviceNo: one.deviceNo, address: this.req3.address + one.cI});
        }
      });
      this.api.device.batchBindDevice([this.req3, this.next]);
      // 批量分配
    } else if (this.isMulti && this.isDivideConV) {
      this.req4.devices = [];
      this.data.map((one) => {
        if (one.checked) {
          this.req4.devices.push(one.deviceNo);
        }
      });
      this.api.device.batchUpdateCompany([this.req4, this.next]);
    }
  }

  deleteConfirm(one) {
    this.modalService.confirm({
      nzTitle  : '<i> </i>',
      nzContent: '<b>' + this.translate.instant('confirmDeleteThisRecord') + '</b>',
      nzOnOk   : () => this.delete(one)
    });
  }

  delete(one) {
    this.isLoading = true;
    this.api.device.delDevice([{deviceNo: one.deviceNo}, this.next]);
  }

  // 批量的方法
  allUpdate() {
    this.isMulti = true;
    this.isUpdate = true;
  }

  multiAdd() {
    this.isUpdate = false;
    this.req = {};
    this.try = {
      trans: {startSN: 'startSN', num: 'addNum', type: 'type', companyNo: 'selectCompany'},
      field: ['startSN', 'num', 'type', 'companyNo']
    };
    this.isMulti = true;
    this.isMultiAddConV = true;
  }

  allDivide() {
    this.req4 = {};
    this.isMulti = true;
    this.isDivideConV = true;
    this.try = {
      trans: {companyNo: 'selectCompany', influxdbDatabaseNo: 'selectCompanyInfluxDBTable'},
      field: ['companyNo', 'influxdbDatabaseNo']};
  }

  allBind() {
    this.isMulti = true;
    this.req3 = {};
    this.selectedGateways = [];
    this.try = {
      trans: {address: 'bindStartTongxinAddr'},
      field: ['address'],
      num: ['address']
    };
    this.isBindConV = true;
  }

  allDelete() {
    this.modalService.confirm({
      nzTitle  : '<i> </i>',
      nzContent: '<b>' + this.translate.instant('confirmDeleteSelectedRecord') + '</b>',
      nzOnOk   : () => this.deleteAll()
    });
  }

  deleteAll() {
    const obj = [];
    this.data.map((one) => {
      if (one.checked) {
        obj.push(one.deviceNo);
      }
    });
    this.isLoading = true;
    this.api.device.batchDelDevice([obj, this.next]);
  }

  allUnbind() {
    this.modalService.confirm({
      nzTitle  : '<i> </i>',
      nzContent: '<b>' + this.translate.instant('confirmUnbindSelectedRecord') + '</b>',
      nzOnOk   : () => this.unbindAll()
    });
  }

  unbindAll() {
    this.isLoading = true;
    const obj = [];
    this.data.map(one => {
      if (one.checked) {
        obj.push(one.deviceNo);
      }
    });
    this.api.device.batchUnBindDevice([obj, this.next]);
  }

  toggleOn(one) {
    one.isLoading = true;
    this.api.device.mountUninstallDevice([{deviceNo: one.deviceNo, state: one.state === 0 ? 1 : 0}, (data) => {
      if (data && data.code === 1) {
        this.search();
        this.message.success(data && data.message ? data.message : '操作成功');
      } else {
        this.message.error(data && data.message ? data.message : '操作失败');
      }
      one.isLoading = false;
    }]);
  }

  getAllInfluxDB() {
    this.isLoading = true;
    this.influxDBTableList = [];

    this.api.influxdbTables.getAll( ['', (data) => {
      if (data && data.constructor === Array) {
        this.influxDBTableList = data;
      } else {
        this.message.error(data && data.message ? data.message : '获取数据失败');
      }
      this.isLoading = false;
    }]);
  }

  getInfluxDBByCompany(value) {
    this.companyInfluxDBTableList = [];
    if (value) {
      this.isLoading = true;
      this.api.influxdbTables.getByCompanyNo( [{companyNo: value}, (data) => {
        if (data && data.constructor === Array) {
          this.companyInfluxDBTableList = data;
        } else {
          this.message.error(data && data.message ? data.message : '获取数据失败');
        }
        this.isLoading = false;
      }]);
    }

  }

  onAddChangeCompany(value) {
    this.req.influxdbDatabaseNo = null;
    this.companyInfluxDBTableList = [];
    this.getInfluxDBByCompany(value);
  }
  onDivideChangeCompany(value) {
    this.req4.influxdbDatabaseNo = null;
    this.companyInfluxDBTableList = [];
    this.getInfluxDBByCompany(value);
  }
}
