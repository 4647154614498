import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-item',
  templateUrl: './search-item.component.html'
})
export class SearchItemComponent implements OnInit {

  @Input() label: any;
  @Input() value: any;
  @Input() type: any = 'text';
  @Input() inModal: any = false;
  @Input() readonly: any = false;
  @Input() maxlength: any = 200;
  @Input() isMust: any = false;
  @Input() min = 0;
  @Output() valueChange = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  onChange() {
    this.valueChange.emit(this.value);
  }
}
