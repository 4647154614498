import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RoutingModule} from './router.module';
import {NgZorroAntdModule} from 'ng-zorro-antd';
import {FormsModule} from '@angular/forms';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {NgxEchartsModule} from 'ngx-echarts';

// 主模块注册
import {LayoutModule} from './layout/layout.module';
// 主组件注册
import {AppComponent} from './app.component';
import {LoadingComponent} from './component/loading/loading.component';
// 国际化相关内容
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
// http
import {HTTP_INTERCEPTORS} from '@angular/common/http';

export function createTranslateHttpLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// 配置 angular i18n
import {registerLocaleData} from '@angular/common';
import zh from '@angular/common/locales/zh';

registerLocaleData(zh);
 // 配置 ng-zorro-antd 国际化
import {NZ_I18N, en_US, zh_CN} from 'ng-zorro-antd';

// 相关的服务
import {AuthInterceptor} from './service/token';
import {HttpService} from './service/http';
import {ApiService} from './service/api';
import {AuthService} from './service/auth';
import {PermissionService} from './service/permission';
import {MenuService} from './service/menu';
import {CheckService} from './service/check';
import {StringUtil} from './service/StringUtil';

@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    NgxEchartsModule,
    LayoutModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RoutingModule,
    BrowserAnimationsModule,
    NgZorroAntdModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateHttpLoader),
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {provide: NZ_I18N, useValue: zh_CN},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    HttpService,
    ApiService,
    AuthService,
    PermissionService,
    MenuService,
    CheckService,
    StringUtil
  ],
  bootstrap: [
    AppComponent,
  ]
})
export class AppModule {
}
