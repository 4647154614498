import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../service/api';
import {NzMessageService} from 'ng-zorro-antd';
import {TranslateService} from '@ngx-translate/core';
import {fadeIn} from '../../animation/fade-in';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styles: [],
  animations: [fadeIn]
})
export class LogComponent implements OnInit {

  req: any = {};
  data = [];
  isLoading = false;
  page: any = 1;
  rows: any = 10;
  total: any;

  constructor(private api: ApiService, private message: NzMessageService, private translate: TranslateService) {
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.isLoading = true;
    this.api.loginlog.getAll(this.page, this.rows, ['', this.next]);
  }

  onSearch() {
    this.page = 1;
    this.query();
  }

  query() {
    this.isLoading = true;
    const req = {
      startTime: this.req.startTime ? new Date(this.req.startTime).getTime() : '',
      endTime: this.req.endTime ? new Date(this.req.endTime).getTime() : ''
    };
    this.api.loginlog.getByTime(this.page, this.rows, [req, this.next]);
  }

  onGetAll() {
    this.page = 1;
    this.req = {};
    this.getData();
  }

  next = (data) => {
    if ( data && data.rows && data.rows.constructor === Array) {
      this.data = data.rows;
      this.total = data.total;
    } else {
      this.message.error(data.message || this.translate.instant('wrongToGetData'));
    }
    this.isLoading = false;
  }

  searchData() {
    if (this.req.startTime || this.req.endTime) {
      this.query();
    } else {
      this.getData();
    }
  }
}
