import {RouterModule, Routes} from '@angular/router';
import {LayoutComponent} from './layout/layout.component';
import {AuthService} from './service/auth';
import {HomeComponent} from './main/home/home.component';
import {LogComponent} from './main/log/log.component';
import {RoleComponent} from './main/role/role.component';
import {UserComponent} from './main/user/user.component';
import {GatewayComponent} from './main/gateway/gateway.component';
import {GatewaySeeComponent} from './main/gateway-see/gateway-see.component';
import {AreaMgrComponent} from './main/area-mgr/area-mgr.component';
import {ModuleMgrComponent} from './main/module-mgr/module-mgr.component';
import {MqttSetMgrComponent} from './main/mqtt-set-mgr/mqtt-set-mgr.component';
import {DataSumComponent} from './main/data-sum/data-sum.component';
import {HistoryDataComponent} from './main/history-data/history-data.component';
import {CurrentDataComponent} from './main/current-data/current-data.component';
import {PartMgrComponent} from './main/part-mgr/part-mgr.component';
import {SetModuleMgrComponent} from './main/set-module-mgr/set-module-mgr.component';
import {TopicModuleMgrComponent} from './main/topic-module-mgr/topic-module-mgr.component';
import {SensorMgrComponent} from './main/sensor-mgr/sensor-mgr.component';
import {SensorDetailComponent} from './main/sensor-detail/sensor-detail.component';
import {ModuleDetailComponent} from './main/module-detail/module-detail.component';
import {TopicModuleDetailComponent} from './main/topic-module-detail/topic-module-detail.component';
import {TopicModuleWayComponent} from './main/topic-module-way/topic-module-way.component';
import {InfluxdbComponent} from './main/influxdb/influxdb.component';
import {SensorTopicWayComponent} from './main/sensor-topic-way/sensor-topic-way.component';
import {CompanyComponent} from './main/company/company.component';
import {RetentionPolicyMgrComponent} from "./main/retention-policy-mgr/retention-policy-mgr.component";

const AppRoutes: Routes = [
  {path: '', redirectTo: '/extra/login', pathMatch: 'full'},
  {path: 'app', component: LayoutComponent},
  {path: 'extra', loadChildren: './extra/extra.module#ExtraModule'},
  {path: '**', redirectTo: '/extra/login', pathMatch: 'full'},
];
export const RoutingModule = RouterModule.forRoot(AppRoutes, {useHash: true});

const routes: Routes = [
  {
    path: 'app',
    component: LayoutComponent,
    canActivateChild: [AuthService],
    children: [
      {path: '', redirectTo: '/extra/login', pathMatch: 'full'},
      {path: 'home', component: HomeComponent},
      {path: 'deviceCenter/gatewayMgr', component: GatewayComponent},
      {path: 'deviceCenter/gatewayMgr/gatewaySee', component: GatewaySeeComponent},
      {path: 'deviceCenter/sensorMgr', component: SensorMgrComponent},
      {path: 'deviceCenter/sensorMgr/sensorDetail', component: SensorDetailComponent},
      {path: 'deviceCenter/sensorMgr/sensorDetail/sensorTopicWay', component: SensorTopicWayComponent},
      {path: 'deviceCenter/moduleMgr', component: ModuleMgrComponent},
      {path: 'deviceCenter/moduleMgr/moduleDetail', component: ModuleDetailComponent},
      {path: 'deviceCenter/topicModuleMgr', component: TopicModuleMgrComponent},
      {path: 'deviceCenter/topicModuleMgr/topicModuleDetail', component: TopicModuleDetailComponent},
      {path: 'deviceCenter/topicModuleMgr/topicModuleDetail/topicModuleWay', component: TopicModuleWayComponent},
      {path: 'deviceCenter/setModuleMgr', component: SetModuleMgrComponent},
      {path: 'deviceCenter/mqttSetMgr', component: MqttSetMgrComponent},
      {path: 'systemMgr/userCenter', component: UserComponent},
      {path: 'systemMgr/influxdbMgr', component: InfluxdbComponent},
      {path: 'systemMgr/influxdbMgr/retentionPolicyMgr', component: RetentionPolicyMgrComponent},
      {path: 'systemMgr/customCenter', component: CompanyComponent},
      {path: 'systemMgr/roleMgr', component: RoleComponent},
      {path: 'systemMgr/partMgr', component: PartMgrComponent},
      {path: 'systemMgr/areaMgr', component: AreaMgrComponent},
      {path: 'systemMgr/logMgr', component: LogComponent},
      {path: 'dataCenter/currentData', component: CurrentDataComponent},
      {path: 'dataCenter/dataSum', component: DataSumComponent},
      {path: 'dataCenter/historyData', component: HistoryDataComponent},
    ]
  }
];

export const LayoutRoutingModule = RouterModule.forChild(routes);

