import {Component, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../service/api';
import {CheckService} from '../../service/check';
import {NzMessageService, NzModalService} from 'ng-zorro-antd';
import {TranslateService} from '@ngx-translate/core';
import {fadeIn} from '../../animation/fade-in';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  animations: [fadeIn],
  styles: []
})
export class RoleComponent implements OnInit {
  @ViewChild('tree') tree: any;
  req: any = {};
  data: any = [];
  isLoading = false;
  isVisible = false;
  isUpdate = false;
  userName = '';
  permissions = [];
  defaultCheckedKeys = [];
  defaultExpandedKeys = [];
  nodes = [];

  constructor(private api: ApiService, private message: NzMessageService, private translate: TranslateService,
              private modalService: NzModalService, private ck: CheckService) {
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.getData();
    this.getPermissions();
  }

  next = (data) => {
    if (data && data.constructor === Array) {
      this.data = data;
    } else if (data && data.code === 1) {
      this.message.success(data.message ? data.message : '操作成功');
      this.isVisible = false;
    } else if (data && data.code === 0) {
      this.message.error(data.message ? data.message : '操作失败');
    } else {
      this.message.error(data && data.message ? data.message : '获取数据失败');
    }
    this.isLoading = false;
  }

  getData() {
    this.isLoading = true;
    this.api.role.getAll(['', this.next]);
  }

  getPermissions() {
    this.isLoading = true;
    this.api.permission.getAll(['', (data) => {
      if (data.constructor === Array) {
        this.permissions = data;
      } else {
        this.message.error(data && data.message ? data.message : '获取数据失败');
      }
      this.isLoading = false;
    }]);
  }

  initPermissions(data) {
    const obj = [];
    if (data && data.constructor === Array && data.length > 0) {
      data.map((one, i) => {
        obj[i] = {
          title: one.parentSidebarName,
          key: one.parentSidebarName,
          selectable: false,
          children: []
        };
        if (one.sidebarVos.length > 0) {
          one.sidebarVos.map((two, j) => {
            obj[i].children[j] = {
              title: two.sidebarName,
              key: two.sidebarName,
              selectable: false,
              children: [],
              isLeaf: two.permissionShowVos.length === 0
            };
            if (two.permissionShowVos.length > 0) {
              two.permissionShowVos.map((three, k) => {
                obj[i].children[j].children[k] = {
                  title: three.permissionName,
                  key: three.permissionName,
                  selectable: false,
                  isLeaf: true
                };
              });
            }
          });
        }
      });
      this.nodes = obj;
    }
  }

  add() {
    this.req = {};
    this.isUpdate = false;
    this.initPermissions([...this.permissions]);
    this.isVisible = true;
  }

  edit(one) {
    this.isUpdate = true;
    this.req = {...one};
    this.api.permission.getPermissionByRoleNo([{roleNo: one.roleNo}, (data) => {
      this.initPermissions(data);
      this.isVisible = true;
    }]);
  }

  cancel() {
    this.isVisible = false;
  }

  save() {
    this.isLoading = true;
    this.req.permissions = this.getSelected();
    if (this.isUpdate) {
      this.api.role.updateRole([this.req, this.next]);
    } else {
      this.api.role.addRole([this.req, this.next]);
    }
  }

  getSelected() {
    const nodes = this.tree.getTreeNodes();
    const obj = [];
    if (nodes.length > 0) {
      nodes.map((one) => {
        if (one.children.length > 0) {
          one.children.map((two) => {
            if (two.children.length === 0 && two.isChecked) {
              obj.push(two.key);
            } else if (two.children.length > 0) {
              two.children.map((three) => {
                if (three.isChecked) {
                  obj.push(three.key);
                }
              });
            }
          });
        } else if (one.isChecked) {
          obj.push(one.key);
        }
      });
      return obj;
    }
  }

  deleteConfirm(one) {
    this.modalService.confirm({
      nzTitle  : '<i></i>',
      nzContent: '<b>' + this.translate.instant('confirmDeleteThisRecord') + '</b>',
      nzOnOk   : () => this.delete(one)
    });
  }

  delete(one) {
    this.api.role.deleteRole([{roleNo: one}, this.next]);
  }
}
