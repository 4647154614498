import { Component, OnInit } from '@angular/core';
import {fadeIn} from '../../animation/fade-in';
import {ApiService} from '../../service/api';
import {NzMessageService, NzModalService} from 'ng-zorro-antd';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-gateway-see',
  templateUrl: './gateway-see.component.html',
  styles: [],
  animations: [fadeIn]
})
export class GatewaySeeComponent implements OnInit {

  isLoading = false;
  data = [];
  deviceNo = '';
  name = '';
  wayData = [];
  deviceData = [];
  constructor(private api: ApiService, private message: NzMessageService, private router: Router,
              private ac: ActivatedRoute, private modalService: NzModalService, private translate: TranslateService) { }

  ngOnInit() {
    this.init();
  }

  init() {
    this.ac.queryParams.subscribe((e) => {
      this.deviceNo = e.deviceNo;
      this.name = e.name;
      this.getDeviceData();
      this.getWayData();
    });
  }

  next = (data) => {
    if (data && data.constructor === Array) {
      this.deviceData = data;
    } else if (data.code === 1) {
      this.message.success(data && data.message ? data.message : '操作成功');
      this.getDeviceData();
      this.getWayData();
    } else if (data.code === 0) {
      this.message.error(data && data.message ? data.message : '操作失败');
    } else {
      this.message.error(data && data.message ? data.message : '获取数据失败');
    }
    this.isLoading = false;
  }

  getDeviceData() {
    this.isLoading = true;
    this.api.device.getSensorByMaxDevice([{deviceNo: this.deviceNo}, this.next]);
  }

  getWayData() {
    // this.isLoading = true;
    // this.api.probes.getProbesByGateway([{deviceNo: this.deviceNo}, (data) => {
    //   if (data && data.constructor === Array) {
    //     this.wayData = data;
    //   } else {
    //     this.message.error(data && data.message ? data.message : '获取数据失败');
    //   }
    //   this.isLoading = false;
    // }]);
  }

  toDetail(one) {
    this.router.navigate(['/app/deviceCenter/sensorMgr/sensorDetail'],
      {queryParams: {deviceNo: one.deviceNo, name: one.name, sn: one.sn, type: one.type}});
  }

  unbind(one) {
    this.modalService.confirm({
      nzTitle  : '<i> </i>',
      nzContent: '<b>' + this.translate.instant('confirmUnbindThisRecord') + '</b>',
      nzOnOk   : () => this.unbindOne(one)
    });
  }

  unbindOne(one) {
    this.isLoading = true;
    this.api.device.unBindDevice([{deviceNo: one.deviceNo}, this.next]);
  }
}
