import { Component, OnInit } from '@angular/core';
import {fadeIn} from '../../animation/fade-in';
import {ApiService} from '../../service/api';
import {NzMessageService, NzModalService} from 'ng-zorro-antd';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CheckService} from '../../service/check';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styles: [],
  animations: [fadeIn]
})
export class CompanyComponent implements OnInit {

  constructor(private api: ApiService, private message: NzMessageService, private router: Router,
              private modalService: NzModalService, private translate: TranslateService,
              private ck: CheckService) { }

  isLoading = false;
  data = [];
  req: any = {};
  roles = [];
  isAddConV = false;
  isUpdate = false;
  procs = [];
  citys = [];
  areas = [];
  ngOnInit() {
    this.init();
  }

  init() {
    this.getData();
    this.getProc();
    this.getRoles();
  }

  next = (data) => {
    if (data.constructor === Array) {
      this.data = data;
    } else if (data.code === 1) {
      this.message.success(data.message ? data.message : '操作成功');
      this.isAddConV = false;
      this.getData();
    } else if (data.code === 0) {
      this.message.error(data.message ? data.message : '操作失败');
    } else {
      this.message.error(data.message ? data.message : '获取数据失败');
    }
    this.isLoading = false;
  }

  getData() {
    this.isLoading = true;
    this.api.company.getAll(['', this.next]);
  }

  getProc() {
    this.api.province.getAll(['', (data) => {
      if (data.constructor === Array) {
        this.procs = data;
      } else {
        this.message.error(data.message ? data.message : '获取数据失败');
      }
    }]);
  }

  getCitys(province, city) {
    this.api.city.getByProvince([{province}, (data) => {
      if (data.constructor === Array) {
        this.citys = data;
        this.req.city = city;
      } else {
        this.message.error(data.message ? data.message : '获取数据失败');
      }
    }]);
  }

  getAreas(city, area) {
    this.api.area.getByCity([{city}, (data) => {
      if (data.constructor === Array) {
        this.areas = data;
        this.req.area = area;
      } else {
        this.message.error(data.message ? data.message : '获取数据失败');
      }
    }]);
  }

  getRoles() {
    this.api.role.getAll(['', (data) => {
      if (data.constructor === Array) {
        this.roles = data;
      } else {
        this.message.error(data.message ? data.message : '获取数据失败');
      }
    }]);
  }

  update(one) {
    this.isUpdate = true;
    this.req = {...one};
    this.isAddConV = true;
    this.getCitys(one.province, one.city);
    this.getAreas(one.city, one.area);
  }

  deleteConfirm(one) {
    this.modalService.confirm({
      nzTitle  : '<i> </i>',
      nzContent: '<b>' + this.translate.instant('confirmDeleteThisRecord') + '</b>',
      nzOnOk   : () => this.delete(one)
    });
  }

  delete(one) {
    this.isLoading = true;
    this.api.company.delCompany([{companyNo: one.companyNo}, this.next]);
  }

  add() {
    this.req = {};
    this.isUpdate = false;
    this.isAddConV = true;
  }

  cancel() {
    this.isAddConV = false;
  }

  save() {
    if (this.isUpdate) {
      this.api.company.updateCompany([this.req, this.next]);
    } else {
      this.api.company.addCompany([this.req, this.next]);
    }
  }
}
