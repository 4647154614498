// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  test: false,
  // baseUrl: 'https://cloud.witium.com/api',
  offlineFlg: 'OFL', // 离线部署flg
  // baseUrl: 'http://cloud-test.witium.com/api', // 对外访问测试网地址
  // baseUrl: 'http://172.16.1.53:8092', // cy
  baseUrl: '/witcloud/api', // cy
  // baseUrl: 'http://172.16.1.52:8094', // zz
  // baseUrl: 'http://cloud.witium.com.cn/api/v2', // 测试
  // baseUrl: 'http://jiecloud.witium.com/witcloud/api/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in prtion mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
