import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-sum',
  templateUrl: './data-sum.component.html',
  styles: []
})
export class DataSumComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
