// 引入 angular 动画组件
import {trigger, style, state, transition, animate} from '@angular/animations';

export const fadeIn = trigger('fade', [
  transition('void => *', [
    style({opacity: 0}),
    animate('1000ms ease-in', style({opacity: 1}))
  ]),
  transition('* => void', [
    animate('1000ms ease-in', style({opacity: 0}))
  ])
]);
