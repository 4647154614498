import {Component, OnInit} from '@angular/core';
import {fadeIn} from '../../animation/fade-in';
import {ApiService} from '../../service/api';
import {NzMessageService} from 'ng-zorro-antd';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [fadeIn]
})
export class HomeComponent implements OnInit {

  constructor(private api: ApiService, private message: NzMessageService,
              private translate: TranslateService, private router: Router) {
  }

  chartRadarOption;
  charLineOption;
  dailyUseRateOption;
  dailyUseTimesRateOption;
  deviceTypeOption;
  oflflg = false;

  device = {
    total: 108923,
    runTime: 108923,
    msgNum : 123,
    useRate : 96,
    todayTime: 1,
  }

  useList = [{
    rank: 1,
    name: '神州澳亚',
    useTime: 3,
    weekRaise: 1.8,
    url: 'http://pasture.witium.com.cn',
    title: '牧场物业化管理系统',
    description: '适用场景：奶牛场，其他畜牧业养殖',
    total: 120000,
    useRate: 100,
    img: '../../../assets/img/muchang.jpg',
  }, {
    rank: 2,
    name: '博世',
    useTime: 3,
    weekRaise: 1.3,
    url: 'https://bosch.logistics.witium.com/',
    title: '物流管理系统',
    description: '适用场景：物流仓库，冷链运输等',
    total: 120000,
    useRate: 100,
    img: '../../../assets/img/bosch.jpg',
  }, {
    rank: 3,
    name: '中石油',
    useTime: 3,
    weekRaise: 1.0,
    url: 'http://ct.oilfield.witium.com',
    title: '油井监控系统',
    description: '适用场景：各类长停井的油井压力检测',
    total: 120000,
    useRate: 100,
    img: '../../../../assets/img/oil.jpg',
  }, {
    rank: 4,
    name: '杭州杰牌',
    useTime: 3,
    weekRaise: 0.6,
    url: 'http://jiecloud.witium.com.cn',
    title: '减速机健康管理系统',
    description: '适用场景：工业减速机，齿轮箱的故障预测',
    total: 120000,
    useRate: 100,
    img: '../../../../assets/img/jie.png',
  }, {
    rank: 5,
    name: '上海辉度',
    useTime: 3,
    weekRaise: 0.53,
    url: 'https://wtlink.witium.com/',
    title: '空压机数据管理系统',
    description: '适用场景：空压机运行保养维护',
    total: 120000,
    useRate: 100,
    img: '../../../../assets/img/aircompress.jpeg',
  }, {
    rank: 6,
    name: 'ED工厂',
    useTime: 3,
    weekRaise: 32,
    url: 'http://edcloud.witium.com.cn/',
    title: '智慧工厂',
    description: '适用场景：切削机，高精制造',
    total: 120000,
    useRate: 100,
    img: '../../../../assets/img/ed.jpg',
  }, {
    rank: 7,
    name: '常州金葵',
    useTime: 3,
    weekRaise: 0.33,
    url: 'http://cloud.czjkzn.com',
    title: '金葵充电桩管理系统',
    description: '适用场景：电瓶车充电管理系统',
    total: 120000,
    useRate: 100,
    img: '../../../../assets/img/jk.png',
  }, {
    rank: 8,
    name: '虹桥机场',
    useTime: 3,
    weekRaise: 0.32,
    url: 'http://hq.witium.com.cn/',
    title: '运输带减速机监控系统',
    description: '适用场景：物流分拣，航空运输',
    total: 120000,
    useRate: 100,
    img: '../../../../assets/img/hongqiao.jpg',
  }];

  ngOnInit() {
    this.init();
    this.oflflg = environment.offlineFlg == 'OFL' ? true : false;
  }

  init() {
    // device = {
    //   total: 108923,
    //   runTime: 108923,
    //   msgNum : 123,
    //   useRate : 96,
    // }

    let Dates = new Date();
    const sFirstDay = Dates.getFullYear() + '/' + (Dates.getMonth() +1) + '/01 00:00:00';
    const sFirstToday = Dates.getFullYear() + '/' + (Dates.getMonth() +1) + '/' + (Dates.getDay()) + ' 00:00:00';


    // const year: number = Dates.getFullYear();
    // const month: any = ( Dates.getMonth() + 1 ) < 10 ? '0' + ( Dates.getMonth() + 1 ) : ( Dates.getMonth() + 1 );
    // const day: any = Dates.getDate() < 10 ? '0' + Dates.getDate() : Dates.getDate();

    this.device.runTime = (((new Date().getTime()) - new Date(sFirstDay).getTime()) / 1000 / 60 / 60 * this.device.total);
    this.device.msgNum = this.device.runTime * 3;
    this.device.todayTime = (((new Date().getTime()) - new Date(sFirstToday).getTime()) / 1000 / 60 / 60 * this.device.total) * 3;

    this.useList.forEach( v => {
      v.useTime = this.device.todayTime / (8 - v.rank + 1);
    });

    this.chartRadarOption = {
      title: {
        text: '浏览器占比变化',
        subtext: '纯属虚构',
        top: 10,
        left: 10
      },
      tooltip: {
        trigger: 'item',
        backgroundColor: 'rgba(0,0,250,0.2)'
      },
      legend: {
        type: 'scroll',
        bottom: 10,
        data: (function () {
          var list = [];
          for (var i = 1; i <= 28; i++) {
            list.push(i + 2000 + '');
          }
          return list;
        })()
      },
      visualMap: {
        top: 'middle',
        right: 10,
        color: ['blue', 'green'],
        calculable: true
      },
      radar: {
        indicator: [
          {text: '离线报警', max: 400},
          {text: '阈值报警', max: 400},
          {text: '', max: 400},
          {text: 'Firefox', max: 400},
          {text: 'Chrome', max: 400}
        ]
      },
      series: (function () {
        var series = [];
        for (var i = 1; i <= 1; i++) {
          series.push({
            name: '浏览器',
            type: 'radar',
            symbol: 'none',
            lineStyle: {
              width: 1
            },
            emphasis: {
              areaStyle: {
                color: 'rgba(0,250,0,0.3)'
              }
            },
            data: [
              {
                value: [
                  (40 - i) * 10,
                  (38 - i) * 4 + 60,
                  i * 5 + 10,
                  i * 9,
                  i * i / 2
                ],
                name: i + 2000 + ''
              }
            ]
          });
        }
        return series;
      })()
    };

    this.charLineOption = {
      xAxis: {
        type: 'category',
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
      },
      yAxis: {
        type: 'value'
      },
      series: [{
        data: [820, 932, 901, 934, 1290, 1330, 1320],
        type: 'line',
        smooth: true
      }]
    };

    this.dailyUseRateOption = {
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        show: false,
      },
      grid: {
        left: 0,
        top: 0,
        bottom: 0,
      },
      yAxis: {
        type: 'value',
        show: false,
      },
      series: [{
        symbol: 'none',
        data: [820, 932, 901, 934, 1290, 1330, 1320],
        type: 'line',
        smooth: true,
        areaStyle: {
          color: '#cceafe',
        },
        lineStyle: {
          color: '#28a6fb',
        }
      }]
    };

    this.dailyUseTimesRateOption = {
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        show: false,
      },
      grid: {
        left: 0,
        top: 0,
        bottom: 0,
      },
      yAxis: {
        type: 'value',
        show: false,
      },
      series: [{
        symbol: 'none',
        data: [820, 932, 901, 934, 1290, 1330, 1320],
        type: 'line',
        smooth: true,
        areaStyle: {
          color: '#dff6f8',
        },
        lineStyle: {
          color: '#78d9e3',
        }
      }]
    };

    this.deviceTypeOption = {
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)'
      },
      grid: {
        left: 0,
      },
      legend: {
        orient: 'vertical',
        right: 20,
        top: 200,
        data: ['WTD934G', 'WTD418X', 'WTD966C', 'WTG9X18', 'WTD666C']
      },
      color : [ '#975fe4', '#3aa0ff', '#36cbcb', '#4dcb73', '#fad337', '#f2637b', '#975fe4' ],
      series: [
        {
          name: '设备类型',
          type: 'pie',
          radius: ['54%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '30',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [
            {value: 33500, name: 'WTD934G'},
            {value: 31003, name: 'WTD418X'},
            {value: 2341, name: 'WTD966C'},
            {value: 1353, name: 'WTG9X18'},
            {value: 1564, name: 'WTD666C'}
          ]
        },

      ]
    };
  }

  toDetail(e) {
    window.open(e.url);
  }

}
