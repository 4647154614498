import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../service/api';
import {NzMessageService, NzModalService} from 'ng-zorro-antd';
import {TranslateService} from '@ngx-translate/core';
import {fadeIn} from '../../animation/fade-in';
import {ActivatedRoute, Router} from '@angular/router';
import {CheckService} from '../../service/check';

@Component({
  selector: 'app-topic-module-detail',
  templateUrl: './topic-module-detail.component.html',
  styles: [],
  animations: [fadeIn]
})
export class TopicModuleDetailComponent implements OnInit {

  req: any = {};
  data = [];
  isLoading = false;
  topicModelNo = '';
  topicModelName = '';
  isV = false;
  isUpdate = false;
  probesModels = [];
  try = {};

  policyList = [];

  constructor(private api: ApiService, private message: NzMessageService, private ac: ActivatedRoute,
              private translate: TranslateService, private router: Router, private modalService: NzModalService,
              private ck: CheckService) {
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.ac.queryParams.subscribe((params) => {
      if (params.topicModelNo) {
        this.topicModelNo = params.topicModelNo;
        this.topicModelName = params.topicModelName;
        this.getData();
      }
    });
    this.getProbsModels();
    this.getAllPolicies();
  }

  getData() {
    this.isLoading = true;
    this.api.topicModelInfo.getAll([{topicModelNo: this.topicModelNo}, this.next]);
  }

  getProbsModels() {
    this.isLoading = true;
    this.api.probesModel.getAll(['', (data) => {
      if (data.constructor === Array) {
        this.probesModels = data;
      } else {
        this.message.error(data && data.message ? data.message : '获取数据失败');
      }
      this.isLoading = false;
    }]);
  }

  getAllPolicies() {
    this.isLoading = true;
    this.policyList = [];
    this.api.influxdbPolicy.getAllPolicyByCompany(['', (data) => {
      if (data.constructor === Array) {
        data.forEach(one => {
          this.policyList.push({
            opLevel: one.databaseName + ' : ' + one.retentionPolicyName,
            opValue: one.retentionPolicyNo,
            duration: one.duration
          });
        });
      } else {
        this.message.error(data && data.message ? data.message : '获取数据失败');
      }
      this.isLoading = false;
    }]);
  }

  onSearch() {
    this.query();
  }

  query() {
    this.isLoading = true;
  }

  onGetAll() {
    this.req = {};
    this.getData();
  }

  next = (data) => {
    if (data && data.constructor === Array) {
      this.data = data;
    } else if (data && data.code === 1) {
      this.message.success(data.message ? data.message : '操作成功');
      this.isV = false;
      this.getData();
    } else if (data && data.code === 0) {
      this.message.error(data.message ? data.message : '操作失败');
    } else {
      this.message.error(data && data.message ? data.message : '获取数据失败');
    }
    this.isLoading = false;
  }

  add() {
    this.req = {};
    this.req.frequency = 0;
    this.isUpdate = false;
    this.try = {
      trans: {topicName: 'topicName', topicType: 'topicType',
        dataType: 'dataType', interval: 'inTime', dataInterval: 'dataInterval'},
      field: ['topicName', 'topicType', 'dataType', 'interval'],
      num: ['dataType', 'interval', 'dataInterval']};
    this.isV = true;
  }

  update(one) {
    this.isUpdate = true;
    this.req = {...one};
    this.req.frequency = 0;
    this.req.oldTopicName = one.topicName;

    if (this.policyList && one.retentionPolicyNo) {
      const currPolicy = this.policyList.filter(item => item.opValue === one.retentionPolicyNo);
      if (currPolicy) {
        this.req.duration = currPolicy[0].duration;
      }
    }

    this.try = {
      trans: {topicName: 'topicName', topicType: 'topicType',
        dataType: 'dataType', interval: 'inTime', dataInterval: 'dataInterval'},
      field: ['topicModelNo', 'topicName', 'topicType', 'dataType', 'interval'],
      num: ['dataType', 'interval', 'dataInterval']};
    this.isV = true;
  }

  cancel() {
    this.isV = false;
  }

  save() {
    this.isLoading = true;
    let param: any = {};
    if (this.isUpdate) {
      param = this.req;
      param.topicModelNo = this.topicModelNo;
      if (!this.ck.formCheck(param, this.try)) {
        this.isLoading = false;
        return false;
      }
      this.api.topicModelInfo.updateTopicModelInfo([param, this.next]);
    } else {
      param.topicModelNo = this.topicModelNo;
      param.addTopicModelForInfoVos = [this.req];
      if (!this.ck.formCheck(param.addTopicModelForInfoVos[0], this.try)) {
        this.isLoading = false;
        return false;
      }
      this.api.topicModelInfo.addTopicModelInfo([param, this.next]);
    }
  }

  onChangePolicy() {
    if (this.policyList && this.req.retentionPolicyNo) {
      const currPolicy = this.policyList.filter(item => item.opValue === this.req.retentionPolicyNo);
      if (currPolicy) {
        this.req.duration = currPolicy[0].duration;
      }
    }
  }

  deleteConfirm(one) {
    this.modalService.confirm({
      nzTitle: '<i> </i>',
      nzContent: '<b>' + this.translate.instant('confirmDeleteThisRecord') + '</b>',
      nzOnOk: () => this.delete(one)
    });
  }

  delete(one) {
    this.isLoading = true;
    this.api.topicModelInfo.delTopicModelInfo([{topicModelNo: one.topicModelNo, topicName: one.topicName}, this.next]);
  }

  toConfig(one) {
    this.router.navigate(['/app/deviceCenter/topicModuleMgr/topicModuleDetail/topicModuleWay'],
      {queryParams: {topicModelNo: one.topicModelNo, topicName: one.topicName}});
  }
}
