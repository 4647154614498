import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../service/api';
import {NzMessageService, NzModalService} from 'ng-zorro-antd';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {fadeIn} from '../../animation/fade-in';
import {CheckService} from '../../service/check';

@Component({
  selector: 'app-sensor-topic-way',
  templateUrl: './sensor-topic-way.component.html',
  styles: [],
  animations: [fadeIn]
})
export class SensorTopicWayComponent implements OnInit {

  req: any = {};
  req2;
  data = [];
  isLoading = false;
  deviceNo = '';
  topicName = '';
  isV = false;
  isUpdate = false;
  isSaveOther = false;
  companyNo = '';
  try = {};

  constructor(private api: ApiService, private message: NzMessageService, private ac: ActivatedRoute,
              private translate: TranslateService, private router: Router, private modalService: NzModalService,
              private ck: CheckService) {
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.ac.queryParams.subscribe((params) => {
      if (params.deviceNo) {
        this.deviceNo = params.deviceNo;
        this.topicName = params.topicName;
        this.companyNo = params.companyNo;
        this.getData();
      }
    });
  }

  getData() {
    this.isLoading = true;
    this.api.probes.getAll([{deviceNo: this.deviceNo, topicName: this.topicName}, this.next]);
  }

  next = (data) => {
    if (data && data.constructor === Array) {
      this.data = data;
    } else if (data && data.code === 1) {
      this.message.success(data.message ? data.message : '操作成功');
      this.isV = false;
      this.getData();
    } else if (data && data.code === 0) {
      this.message.error(data.message ? data.message : '操作失败');
    } else {
      this.message.error(data && data.message ? data.message : '获取数据失败');
    }
    this.isLoading = false;
  }

  add() {
    this.req = {unit: '-', isTag: 0, isAlarm: 0};
    // 新增时通道类型初期表示默认值'AI'
    if (this.req.type === undefined) {
      this.req.type = 'AI';
    }
    this.isUpdate = false;
    this.isV = true;
    this.try = {
      trans: {name: 'wayName', alias: 'wayAlias', description: 'wayDescription', type: 'per', isTag: 'isTag',
        isAlarm: 'isAlarm', alarmUp: 'alarmMax', upperAlarmLimit: 'alarmMaxMax', rangeMax: 'max', alarmDown: 'alarmMin',
        lowerAlarmLimit: 'alarmMinMin', alarmInterval: 'alarmInterval', rangeMin: 'min'},
      field: ['deviceNo', 'topicName', 'name', 'alias', 'description', 'type', 'isTag', 'isAlarm'],
      canFloat2: ['alarmUp', 'upperAlarmLimit', 'rangeMax', 'alarmDown', 'lowerAlarmLimit', 'rangeMin'],
      num: ['alarmInterval'],
      eng: ['name', 'alias']
    };
  }

  update(one) {
    this.isUpdate = true;
    this.req = {...one};
    this.req.oldName = one.name;
    this.isV = true;
    this.try = {
      trans: {name: 'wayName', alias: 'wayAlias', description: 'wayDescription', type: 'per', isTag: 'isTag',
        isAlarm: 'isAlarm', alarmUp: 'alarmMax', upperAlarmLimit: 'alarmMaxMax', rangeMax: 'max', alarmDown: 'alarmMin',
        lowerAlarmLimit: 'alarmMinMin', alarmInterval: 'alarmInterval', rangeMin: 'min'},
      field: ['deviceNo', 'topicName', 'name', 'alias', 'description', 'type', 'isTag', 'isAlarm'],
      canFloat2: ['alarmUp', 'upperAlarmLimit', 'rangeMax', 'alarmDown', 'lowerAlarmLimit', 'rangeMin'],
      num: ['alarmInterval'],
      eng: ['name', 'alias']
    };
  }

  cancel() {
    this.isV = false;
    this.isSaveOther = false;
  }

  save(v) {
    this.isLoading = true;
    this.req.deviceNo = this.deviceNo;
    this.req.topicName = this.topicName;
    if (!this.ck.formCheck(this[v], this.try)) {
      this.isLoading = false;
      return false;
    }
    if (this.isV && this.isUpdate) {
      if (this.req.type !== 'DI' && this.req.type !== 'DO' && !this.req.unit) {
        this.message.warning('单位为必填项');
        this.isLoading = false;
        return false;
      }
      this.api.probes.updateProbes([this.req, this.next]);
    } else if (this.isV && !this.isUpdate) {
      if (this.req.type !== 'DI' && this.req.type !== 'DO' && !this.req.unit) {
        this.message.warning('单位为必填项');
        this.isLoading = false;
        return false;
      }
      this.api.probes.addProbes([this.req, this.next]);
    } else if (this.isSaveOther) {
      this.api.probes.saveAsProbesModel([this.req2, this.next]);
    } else {
      this.isLoading = false;
    }
  }

  deleteConfirm(one) {
    this.modalService.confirm({
      nzTitle: '<i> </i>',
      nzContent: '<b>' + this.translate.instant('confirmDeleteThisRecord') + '</b>',
      nzOnOk: () => this.delete(one)
    });
  }

  delete(one) {
    this.isLoading = true;
    this.api.probes.delAProbes([{deviceNo: this.deviceNo, name: one.name, topicName: this.topicName}, this.next]);
  }

  saveOther() {
    this.isSaveOther = true;
    this.req2 = {
      companyNo: this.companyNo,
      newProbesModelName: '',
      deviceNo: this.deviceNo,
      topicName: this.topicName
    };
    this.try = {
      trans: {newProbesModelName: 'moduleName'},
      field: ['newProbesModelName', 'deviceNo']};
  }
}
