import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { PermissionService } from '../../service/permission';
import { Router } from '@angular/router';

@Component({
  selector: 'app-leftmenu',
  templateUrl: './leftmenu.component.html',
  styleUrls: ['./leftmenu.component.scss']
})
export class LeftmenuComponent implements OnInit, OnDestroy {

  constructor(private message: NzMessageService, private permissionS: PermissionService, private router: Router) {
  }

  selectItem: any;
  @Input() isCollapsed;
  @Input()
  get selectedItem() {
    return this.selectItem;
  }
  set selectedItem(selectItem: any) {
    this.selectItem = selectItem;
    this.init();
  }
  dataArr = [{
    name: 'home',
    icon: 'io i-shouye',
    type: 1,
    child: []
  }, {
    name: 'deviceCenter',
    icon: 'io i-shebei',
    type: 0,
    child: [{name: 'gatewayMgr'}, {name: 'sensorMgr'}, {name: 'moduleMgr'},
      {name: 'topicModuleMgr'}]
  }, {
    name: 'systemMgr',
    icon: 'io i-shezhi',
    type: 0,
    child: [{name: 'influxdbMgr'}, {name: 'userCenter'}, {name: 'customCenter'}, {name: 'partMgr'}, {name: 'areaMgr'}, {name: 'logMgr'}]
  }, {
    name: 'dataCenter',
    icon: 'io i-ico_data',
    type: 0,
    child: [{name: 'currentData'}, {name: 'dataSum'}, {name: 'historyData'}]
  }];
  isOpen = {monitoring: false, deviceManage: false, pileManage: false, moduleCenter: false, systemSet: false};
  isSelected = ['', ''];

  ngOnInit() {
    this.init();
    // this.getPermission();
  }

  init() {
    if (this.selectItem && this.selectItem.length > 0) {
      this.isOpen[this.selectItem[0]] = true;
      this.isSelected = this.selectItem;
    }
  }

  ngOnDestroy() {
  }

  // getPermission() {
  //   const arr = [...this.dataArr];
  //   const obj = this.permissionS.getTiny();
  //   arr.map((item, index) => {
  //     if (item.name !== 'home') {
  //       item.child = obj[item.name].child;
  //     }
  //     if (index === arr.length - 1 ) {
  //       this.dataArr = arr;
  //     }
  //   });
  // }

  selectFirstFloor(item) {
    if (item.type) {
      this.isOpen = {monitoring: false, deviceManage: false, pileManage: false, moduleCenter: false, systemSet: false};
      this.isSelected = [item.name];
      this.router.navigate(['app/' + item.name]);
    } else if (!this.isCollapsed) {
      this.isOpen[item.name] = !this.isOpen[item.name];
    }
  }

  selectSecondFloor(item1, item2) {
    const isOpen = {monitoring: false, deviceManage: false, pileManage: false, moduleCenter: false, systemSet: false};
    isOpen[item1] = true;
    this.isOpen = isOpen;
    this.isSelected = [item1, item2];
    this.router.navigate(['app/' + item1 + '/' + item2]);
  }

  collapseChange(e) {
    if (!e) {
      const isOpen = {monitoring: false, deviceManage: false, pileManage: false, moduleCenter: false, systemSet: false};
      isOpen[this.isSelected[0]] = true;
      this.isOpen = isOpen;
    }
  }
}
