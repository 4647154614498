import {Injectable} from '@angular/core';
import {isInteger, toNumber} from 'ng-zorro-antd';

@Injectable()
export class StringUtil {
  constructor() {}

  /**
   * 字符串是否不为空
   */
  public isNotEmpty(str): boolean {
    if (str === undefined || str === null || str === '') {
      return false;
    } else {
      return true;
    }
  }

  /**
   * 字符串是否为空
   */
  public isEmpty(str): boolean {
    if (str === undefined || str === null || str === '') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * 字符转数字
   * 例: "1" → 1
   */
  public strToNum(str): number {
    if (str === undefined || str === null || str === '') {
      return null;
    } else if (isInteger(+str))  {
      return toNumber(str);
    } else {
      return str;
    }
  }

}
