import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../service/api';
import {NzMessageService, NzModalService} from 'ng-zorro-antd';
import {TranslateService} from '@ngx-translate/core';
import {fadeIn} from '../../animation/fade-in';
import {Router} from '@angular/router';
import {CheckService} from '../../service/check';

@Component({
  selector: 'app-module-mgr',
  templateUrl: './module-mgr.component.html',
  styles: [],
  animations: [fadeIn]
})
export class ModuleMgrComponent implements OnInit {

  req: any = {};
  req2;
  data = [];
  isLoading = false;
  isAddConV = false;
  isSaveOther = false;
  isUpdate = false;
  companys = [];
  moduleName = '';
  try: any = {};

  constructor(private api: ApiService, private message: NzMessageService, private modalService: NzModalService,
              private translate: TranslateService, private router: Router, private ck: CheckService) {
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.getData();
    this.getCompanys();
  }

  getData() {
    this.isLoading = true;
    this.api.probesModel.getAll(['', this.next]);
  }

  getCompanys() {
    this.isLoading = true;
    this.api.company.getAll(['', (data) => {
      if (data && data.constructor === Array) {
        this.companys = data;
      } else {
        this.message.error(data && data.message ? data.message : '获取数据失败');
      }
      this.isLoading = false;
    }]);
  }

  onSearch() {
    this.getData();
  }

  onGetAll() {
    this.moduleName = '';
    this.getData();
  }

  next = (data) => {
    if (data && data.constructor === Array) {
      this.data = data.filter(one => one.probesModelName.indexOf(this.moduleName) > -1);
    } else if (data && data.code === 1) {
      this.message.success(data.message ? data.message : '操作成功');
      this.isAddConV = false;
      this.isSaveOther = false;
      this.init();
    } else if (data && data.code === 0) {
      this.message.error(data.message ? data.message : '操作失败');
    } else {
      this.message.error(data && data.message ? data.message : '获取数据失败');
    }
    this.isLoading = false;
  }

  toDetail(one) {
    this.router.navigate(['/app/deviceCenter/moduleMgr/moduleDetail'],
      {queryParams: {probesModelNo: one.probesModelNo, probesModelName: one.probesModelName}});
  }

  saveOther(one) {
    this.isSaveOther = true;
    this.req2 = {
      companyNo: one.companyNo,
      newProbesModelName: '',
      probesModelNo: one.probesModelNo
    };
    this.try = {trans: {newProbesModelName: 'moduleName'}, field: ['newProbesModelName', 'probesModelNo']};
  }

  add() {
    this.req = {};
    this.isUpdate = false;
    this.isAddConV = true;
    this.try = {trans: {probesModelName: 'moduleName', num: 'wayNum'}, field: ['probesModelName', 'num']};
  }

  update(one) {
    this.isUpdate = true;
    this.req = {...one};
    this.isAddConV = true;
    this.try = {trans: {probesModelName: 'moduleName'}, field: ['probesModelNo', 'probesModelName']};
  }

  cancel(v) {
    this[v] = false;
  }

  save(v) {
    this.isLoading = true;
    if (!this.ck.formCheck(this[v], this.try)) {
      this.isLoading = false;
      return;
    }
    if (this.isUpdate && this.isAddConV) {
      this.api.probesModel.updateProbesModel([this.req, this.next]);
    } else if (!this.isUpdate && this.isAddConV) {
      this.api.probesModel.addProbesModel([this.req, this.next]);
    } else if (this.isSaveOther) {
      this.api.probesModel.saveAsProbesModel([this.req2, this.next]);
    } else {
      this.isLoading = false;
    }
  }

  deleteConfirm(one) {
    this.modalService.confirm({
      nzTitle  : '<i> </i>',
      nzContent: '<b>' + this.translate.instant('confirmDeleteThisRecord') + '</b>',
      nzOnOk   : () => this.delete(one)
    });
  }

  delete(one) {
    this.isLoading = true;
    this.api.probesModel.delProbesModel([{probesModelNo: one.probesModelNo}, this.next]);
  }
}
