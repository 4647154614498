import { Injectable } from '@angular/core';
import { ApiService } from './api';
import { Router } from '@angular/router';
import { MenuService } from './menu';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private permissionAll = {};
  private permissionTiny = {};
  private functions = [];

  constructor(private api: ApiService, private menu: MenuService, private router: Router) {
  }

  getAllPermission(type, data) {
    // let roleNo = '';
    // if (data.roleNo) {
    //   roleNo = data.roleNo;
    // } else {
    //   this.router.navigate(['/extra/login']);
    //   return;
    // }
    // this.permissionAll = [];
    // this.api.permission.getPermissionByRoleNo([{roleNo: roleNo}, (resp: any) => {
    //   if (resp.constructor === Array) {
    //     this.permissionAll = resp;
    //     window.sessionStorage.setItem(encodeURIComponent('_AMap_AMap.OuterEnter'), this.transform(JSON.stringify(resp)));
    //     this.getTinyPermission(type);
    //   } else {
    //     this.router.navigate(['/extra/login']);
    //   }}]);
  }

  getTinyPermission(type) {
    // this.permissionTiny = [];
    // const all: any = this.permissionAll;
    // const obj: any = {};
    // const opers = [];
    // if (all.constructor === Array) {
    //   all.map((item: any, index) => {
    //     obj[this.menu.transformFront(item.parentSidebarName)] = {};
    //     obj[this.menu.transformFront(item.parentSidebarName)].child = [];
    //     // if ( index === 4 ) {
    //     //   obj[this.menu.transformFront(item.parentSidebarName)].child.push({name: 'personSet'});
    //     // }
    //     if (item.sidebarVos.constructor === Array && item.sidebarVos.length > 0) {
    //       item.sidebarVos.map((side: any, sIndex) => {
    //         let check = false;
    //         if (side.permissionShowVos.constructor === Array && side.permissionShowVos.length > 0) {
    //           side.permissionShowVos.map((oper: any, oIndex) => {
    //             if (oper.checked && (oper.permissionName === 'factoryConfig' ||
    //             oper.permissionName === 'monitoringPointConfig' || oper.permissionName === 'cuttingMachineSelect' ||
    //             oper.permissionName === 'machineRoomSelect')) {
    //               obj[this.menu.transformFront(oper.permissionName)] = {};
    //             }
    //             if (oper.checked) {
    //               check = true;
    //               opers.push(oper.permissionName);
    //             }
    //             if (oIndex === side.permissionShowVos.length - 1 && check) {
    //               obj[this.menu.transformFront(item.parentSidebarName)].child.push({name: this.menu.transformFront(side.sidebarName)});
    //             }
    //             if (oIndex === side.permissionShowVos.length - 1 && sIndex === item.sidebarVos.length - 1 && index === all.length - 1) {
    //               this.permissionTiny = obj;
    //               this.functions = opers;
    //               this.out(type);
    //             }
    //           });
    //         } else if (sIndex === item.sidebarVos.length - 1 && index === all.length - 1) {
    //           this.permissionTiny = obj;
    //           this.functions = opers;
    //           this.out(type);
    //         }
    //       });
    //     } else if (index === all.length - 1) {
    //       this.permissionTiny = obj;
    //       this.functions = opers;
    //       this.out(type);
    //     }
    //   });
    // }
  }

  out(type) {
    const permissionCheckJson = JSON.stringify(this.permissionTiny);
    window.sessionStorage.setItem(encodeURIComponent('_AMap_AMap.OuterEnterKey'), this.transform(permissionCheckJson));
    const funcCheckJson = JSON.stringify(this.functions);
    window.sessionStorage.setItem(encodeURIComponent('_AMap_AMap.InterEnterKey'), this.transform(funcCheckJson));
    if (permissionCheckJson.indexOf('home') && type === 'login') {
      this.router.navigate(['app/home']);
    } else {
      this.router.navigate(['extra/login']);
    }
  }

  // 生成一个随机token的key值
  createTokenKey(length) {
    const chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
      'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
      'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    let num = '';
    for (let i = 0; i < length; i++) {
      const id = parseInt((Math.random() * 60).toString(), 10);
      num += chars[id];
    }
    return num;
  }

  transform(data) {
    const str = data;
    const key = this.createTokenKey(str.length);
    let defaultStr = '';
    for (let i = 0, length = str.length; i < length; i++) {
      defaultStr += str[i] + key[i];
    }
    return encodeURIComponent(defaultStr);
  }

  transback(data) {
    const str = decodeURIComponent(data);
    let defaultStr = '';
    for (let i = 0, length = str.length; i < length; i++) {
      if (i % 2 === 0) {
        defaultStr += str[i];
      }
    }
    return defaultStr;
  }

  getFull(): any {
    let data = window.sessionStorage.getItem(encodeURIComponent('_AMap_AMap.OuterEnter'));
    if (data) {
      data = JSON.parse(this.transback(data));
      return data;
    } else {
      this.router.navigate(['extra/login']);
      return [];
    }
  }

  getTiny(): any {
    let data = window.sessionStorage.getItem(encodeURIComponent('_AMap_AMap.OuterEnterKey'));
    if (data) {
      data = JSON.parse(this.transback(data));
      return data;
    } else {
      this.router.navigate(['extra/login']);
      return [];
    }
  }

  private getFuncs(): any {
    let data = window.sessionStorage.getItem(encodeURIComponent('_AMap_AMap.InterEnterKey'));
    if (data) {
      data = JSON.parse(this.transback(data));
      return data;
    } else {
      this.router.navigate(['extra/login']);
      return [];
    }
  }

  testFuncs(funcName) {
    if (this.functions.length <= 0) {
      this.functions = this.getFuncs();
    }
    if (JSON.stringify(this.functions).indexOf(funcName) > -1) {
      return true;
    } else {
      return false;
    }
  }
}
