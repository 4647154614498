import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  bShow = true;
  _title = [];
  @Input()
  get title(): any {
    return this._title;
  }
  set title(_title: any) {
    this._title = _title;
    this.whenChange();
  }
  @Input()bcShow;
  constructor() { }

  ngOnInit() {
  }

  whenChange() {
    if (this._title[0] === 'home') {
      this.bShow = false;
    } else {
      this.bShow = true;
    }
  }

  goTo(index) {
    if (index > 1 && this._title[1] !== 'historyData') {
      const oldAddr = this._title.join('/');
      const addr = this._title.slice(0, index).join('/');
      window.location.href = window.location.href.replace(oldAddr, addr);
    }
  }

}
