import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../service/api';
import {NzMessageService, NzModalService} from 'ng-zorro-antd';
import {TranslateService} from '@ngx-translate/core';
import {CheckService} from '../../service/check';
import {fadeIn} from '../../animation/fade-in';
import {ActivatedRoute, Router} from '@angular/router';
import {StringUtil} from '../../service/StringUtil';

@Component({
  selector: 'app-retention-policy-mgr',
  templateUrl: './retention-policy-mgr.component.html',
  styles: [],
  animations: [fadeIn]
})
export class RetentionPolicyMgrComponent implements OnInit {

  influxdbDatabaseNo = '';
  canEdit = false;
  defaultDatabase = 1;
  req: any = {};
  data: any = [];
  isLoading = false;
  isVisible = false;
  isUpdate = false;
  companyNo = '';
  companys = [];

  constructor(private api: ApiService, private message: NzMessageService, private translate: TranslateService,
              private modalService: NzModalService, private ck: CheckService, private ac: ActivatedRoute,
              private stringUtil: StringUtil) {
  }

  ngOnInit() {
    this.ac.queryParams.subscribe((param) => {
      this.influxdbDatabaseNo = param.influxdbDatabaseNo;
      this.defaultDatabase = param.defaultDatabase;
      this.companyNo = param.companyNo;
    });
    this.init();
  }

  init() {
    const userCompanyNo = window.sessionStorage.getItem(encodeURIComponent('_AMap_AMap.IndoorMapFunc'));
    // 非默认influxDB 或者 默认influxDB数据
    if (this.defaultDatabase === 0 || this.companyNo === userCompanyNo) {
      this.canEdit = true;
    } else {
      this.canEdit = false;
    }
    this.getData();
  }

  next = (data) => {
    if (data && data.constructor === Array) {
      this.data = data;
    } else if (data && data.code === 1) {
      this.message.success(data.message ? data.message : '操作成功');
      this.init();
      this.isVisible = false;
    } else if (data && data.code === 0) {
      this.message.error(data.message ? data.message : '操作失败');
    } else {
      this.message.error(data && data.message ? data.message : '获取数据失败');
    }
    this.isLoading = false;
  }

  getData() {
    this.isLoading = true;
    this.api.influxdbPolicy.getPolicyByInfluxdb([{influxdbDatabaseNo: this.influxdbDatabaseNo}, this.next]);
  }

  add() {
    this.isUpdate = false;
    this.req = {};
    this.isVisible = true;
  }

  onSetDefalut(one) {
    const param = {
      retentionPolicyNo: one.retentionPolicyNo,
      influxdbDatabaseNo: one.influxdbDatabaseNo,
      retentionPolicyName: one.retentionPolicyName
    };
    this.isLoading = true;
    this.api.influxdbPolicy.updateDefaultPolicy([param, this.next]);
  }

  edit(one) {
    const durationIndex = one.duration.search(/[A-Za-z]/);
    const shardDurationIndex = one.shardDuration.search(/[A-Za-z]/);
    this.isUpdate = true;
    this.req = {...one};
    this.req.duration = one.duration.substring(0, durationIndex);
    this.req.shardDuration = one.shardDuration.substring(0, shardDurationIndex);
    this.isVisible = true;
  }

  save() {
    const param = {
      retentionPolicyNo: this.req.retentionPolicyNo,
      influxdbDatabaseNo: this.req.influxdbDatabaseNo,
      retentionPolicyName: this.req.retentionPolicyName,
      duration: this.req.duration,
      defaultPolicy: this.req.defaultPolicy,
      shardDuration: this.req.shardDuration,
      remark: this.req.remark,
    };

    this.isLoading = true;
    if (this.stringUtil.isNotEmpty(param.duration)) {
      param.duration = this.req.duration + 'h0m0s';
    }
    if (this.stringUtil.isNotEmpty(param.shardDuration)) {
      param.shardDuration = param.shardDuration + 'h0m0s';
    }

    this.isLoading = false;
    if (this.isUpdate) {
      this.api.influxdbPolicy.updateInfluxdbPolicy([param, this.next]);
    } else {
      param.influxdbDatabaseNo = this.influxdbDatabaseNo;
      this.api.influxdbPolicy.addInfluxdbPolicy([param, this.next]);
    }
  }

  cancel() {
    this.req = {};
    this.isVisible = false;
  }

  deleteConfirm(one) {
    this.modalService.confirm({
      nzTitle  : '<i></i>',
      nzContent: '<b>' + this.translate.instant('confirmDeleteThisRecord') + '</b>',
      nzOnOk   : () => this.delete(one)
    });
  }

  delete(one) {
    this.api.influxdbPolicy.delInfluxdbPolicy([{retentionPolicyNo: one.retentionPolicyNo}, this.next]);
  }
}
