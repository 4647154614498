import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../service/api';
import {NzMessageService, NzModalService} from 'ng-zorro-antd';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {fadeIn} from '../../animation/fade-in';
import {CheckService} from '../../service/check';

@Component({
  selector: 'app-sensor-mgr',
  templateUrl: './sensor-mgr.component.html',
  styles: [`
    .btnPart {
      padding: 10px;
    }
  `],
  animations: [fadeIn]
})
export class SensorMgrComponent implements OnInit {

  data = [];
  query: any = {
    model: 1
  };
  isLoading = false;
  isAllCheck = false;
  isIndeterminate = false;
  listOfBind = [{text: '已绑定', value: 1}, {text: '未绑定', value: 0}];
  isAddConV = false;
  isMultiAddConV = false;
  isBindConV = false;
  isMulti = false;
  isDivideConV = false;
  isUpdate = false;
  canbind = false;
  canUnbind = false;
  isCheckedOne = false;
  req: any = {};
  topicModels = [];
  companys = [];
  influxdbs = [];
  scenes = [{sceneNo: '123', sceneName: 'qq'}];
  selectedGateways = [];
  canBindGateways = [];
  req3: any = {};
  req5: any = {};
  try = {};
  page = 1;
  rows = 10;
  total = 0;

  influxDBTableList = [];
  companyInfluxDBTableList = [];

  constructor(private api: ApiService, private message: NzMessageService, private router: Router,
              private modalService: NzModalService, private translate: TranslateService, private ck: CheckService) {
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.getData();
    this.getTopicModels();
    this.getCompanys();
    this.getGateways();
    this.getAllInfluxDB();
  }

  next = (data) => {
    if (data && data.rows && data.rows.constructor === Array) {
      this.data = data.rows;
      this.total = data.total;
      this.oneCheck(-1);
    } else if (data.code === 1) {
      this.message.success(data && data.message ? data.message : '操作成功');
      this.cancel();
      this.getData();
    } else if (data.code === 0) {
      this.message.error(data && data.message ? data.message : '操作失败');
    } else {
      this.message.error(data && data.message ? data.message : '获取数据失败');
    }
    this.isLoading = false;
  }

  getData() {
    this.isLoading = true;
    this.api.device.getAll(this.page, this.rows, [{model: 1}, this.next]);
  }

  search() {
    this.isLoading = true;
    this.api.device.getByTerm(this.page, this.rows, [this.query, this.next]);
  }

  pageChange() {
    this.search();
  }

  getTopicModels() {
    this.isLoading = true;
    this.api.topicModel.getAll(['', (data) => {
      if (data && data.constructor === Array) {
        this.topicModels = data;
      } else {
        this.message.error(data && data .message ? data.message : '获取数据失败');
      }
      this.isLoading = false;
    }]);
  }

  getCompanys() {
    this.isLoading = true;
    this.api.company.getAll(['', (data) => {
      if (data && data.constructor === Array) {
        this.companys = data;
      } else {
        this.message.error(data && data.message ? data.message : '获取数据失败');
      }
      this.isLoading = false;
    }]);
  }

  getGateways() {
    this.isLoading = true;
    this.api.device.getAll(1, 1000000, [{model: 0}, (data) => {
      if (data && data.rows && data.rows.constructor === Array) {
        this.canBindGateways =  data.rows.filter(one => !one.pDeviceNo);
      } else {
        this.message.error(data && data.message ? data.message : '获取数据失败');
      }
      this.isLoading = false;
    }]);
  }

  getAllInfluxDB() {
    this.isLoading = true;
    this.influxDBTableList = [];

    this.api.influxdbTables.getAll( ['', (data) => {
      if (data && data.constructor === Array) {
        this.influxDBTableList = data;
      } else {
        this.message.error(data && data.message ? data.message : '获取数据失败');
      }
      this.isLoading = false;
    }]);
  }

  onSearch() {
    this.page = 1;
    this.query.isBind = null;
    this.listOfBind = [{text: '已绑定', value: 1}, {text: '未绑定', value: 0}];
    this.search();
  }

  onGetAll() {
    this.page = 1;
    this.query = {
      model: 1
    };
    this.listOfBind = [{text: '已绑定', value: 1}, {text: '未绑定', value: 0}];
    this.getData();
  }

  onInput(e) {
    this.selectedGateways = this.canBindGateways.filter(one => one.name.indexOf(e) > -1 || one.sn.indexOf(e) > -1);
  }

  // 复选框的方法
  initCheckArray(b) {
    if (b) {
      this.isCheckedOne = true;
    }
    this.data.map((one, index) => {one.checked = b; one.cI = b ? index : null; });
  }

  checkAll(e) {
    this.isIndeterminate = false;
    this.initCheckArray(e);
    this.checkCanBind();
  }

  oneCheck(i) {
    this.isCheckedOne = true;
    this.isIndeterminate = false;
    this.isAllCheck = false;
    const sum = this.data.filter((one) => one.checked).length;
    if (sum === this.data.length) {
      this.isAllCheck = true;
    } else if (sum === 0) {
      this.isCheckedOne = false;
      this.canbind = false;
      this.canUnbind = false;
      this.data.map(one => one.cI = null);
    } else {
      this.isIndeterminate = true;
      // 如果这个刚刚点击的是被选中, 那么这个cI(check的序列号index)直接赋值就行
      if (i >= 0 && this.data[i].checked) {
        this.data[i].cI = sum - 1;
        // 如果这个刚刚点击的是取消选中,那么需要将这个的序列号清空, 后面所有的序列号都-1
      } else if (!this.data[i].checked) {
        this.data.map(one => one.cI = one.cI > this.data[i].cI ? one.cI - 1 : one.cI);
      }
    }
    this.checkCanBind();
  }

  checkCanBind() {
    const obj = this.data.filter(one => one.checked);
    if (obj.length === 0) {
      this.canbind = false;
      this.canUnbind = false;
    } else {
      const flag: boolean = !!obj[0].pDeviceNo;
      if (obj.filter(one => (!!one.pDeviceNo) === flag).length === obj.length) {
        this.canbind = !flag;
        this.canUnbind = flag;
      } else {
        this.canbind = false;
        this.canUnbind = false;
      }
    }
  }

  // 筛选
  filter(e) {
    this.query.isBind = e;
    this.search();
  }

  companySelected(e) {
    // this.isLoading = true;
    // this.api.influxdbTables.getByCompanyNo([{companyNo: e}, (data) => {
    //   if (data && data.constructor === Array) {
    //     this.influxdbs = data;
    //   } else {
    //     this.message.error(data && data.message ? data.message : '获取数据失败');
    //   }
    //   this.isLoading = false;
    // }]);
  }

  // 跳转详情
  toSee(e) {
    this.router.navigate(['/app/deviceCenter/sensorMgr/sensorDetail'],
      {queryParams: {deviceNo: e.deviceNo, name: e.name, companyNo: e.companyNo, sn: e.sn, type: e.type}});
  }

  // 单个的方法
  add() {
    this.req = {};
    this.isMulti = false;
    this.isAddConV = true;
    this.isUpdate = false;
    this.try = {
      trans: {name: 'sensorName', sn: 'sensorSN', type: 'type', companyNo: 'selectCompany'},
      field: ['name', 'sn', 'type', 'companyNo']};
  }

  bind(one) {
    this.isMulti = false;
    this.selectedGateways = [];
    this.req3 = {
      address: '',
      deviceNo: one.deviceNo,
      influxdbDatabaseNo: one.influxdbDatabaseNo,
      pDeviceNo: ''
    };
    this.isBindConV = true;
    this.try = {
      trans: {address: 'belongAddress'},
      field: ['address', 'deviceNo']};
  }

  unbind(one) {
    this.modalService.confirm({
      nzTitle  : '<i></i>',
      nzContent: '<b>' + this.translate.instant('confirmUnbindThisRecord') + '</b>',
      nzOnOk   : () => this.unbindOne(one)
    });
  }

  unbindOne(one) {
    this.isLoading = true;
    this.api.device.unBindDevice([{deviceNo: one.deviceNo}, this.next]);
  }

  divide(one) {
    this.isMulti = false;
    this.req5 = {...one};
    this.getInfluxDBByCompany(one.companyNo);
    this.try = {
      trans: {companyNo: 'selectCompany', influxdbDatabaseNo: 'selectCompanyInfluxDBTable'},
      field: ['companyNo', 'deviceNo', 'influxdbDatabaseNo']};
    this.isDivideConV = true;
  }

  update(one) {
    this.isMulti = false;
    this.req = {...one};
    this.getInfluxDBByCompany(one.companyNo);
    this.isUpdate = true;
    this.isAddConV = true;
    this.try = {
      trans: {name: 'sensorName', sn: 'sensorSN', type: 'type', companyNo: 'selectCompany'},
      field: ['name', 'sn', 'type', 'companyNo', 'deviceNo']};
  }

  cancel() {
    this.isMultiAddConV = false;
    this.isAddConV = false;
    this.isBindConV = false;
    this.isDivideConV = false;
  }

  getInfluxDBByCompany(value) {
    this.companyInfluxDBTableList = [];
    if (value) {
      this.isLoading = true;
      this.api.influxdbTables.getByCompanyNo( [{companyNo: value}, (data) => {
        if (data && data.constructor === Array) {
          this.companyInfluxDBTableList = data;
        } else {
          this.message.error(data && data.message ? data.message : '获取数据失败');
        }
        this.isLoading = false;
      }]);
    }

  }
  onAddChangeCompany(value) {
    this.req.influxdbDatabaseNo = null;
    this.companyInfluxDBTableList = [];
    this.getInfluxDBByCompany(value);
  }
  onDivideChangeCompany(value) {
    this.req5.influxdbDatabaseNo = null;
    this.companyInfluxDBTableList = [];
    this.getInfluxDBByCompany(value);
  }

  save(v) {
    this.isLoading = true;
    if (!this.ck.formCheck(this[v], this.try)) {
      this.isLoading = false;
      return;
    }
    // 单个新增
    if (!this.isMulti && this.isAddConV && !this.isUpdate) {
      this.req.alarmMethodNos = [];
      this.req.alarmUsers = [];
      this.req.model = 1;
      this.api.device.addSensorOrBlend([this.req, this.next]);
      // 单个编辑
    } else if (!this.isMulti && this.isAddConV && this.isUpdate) {
      this.req.alarmMethodNos = [];
      this.req.alarmUsers = [];
      this.req.model = 1;
      this.api.device.updateSensor([this.req, this.next]);
      // 单个绑定
    } else if (!this.isMulti && this.isBindConV) {
      this.req3.pDeviceNo = this.req3.device.deviceNo;
      this.api.device.gatewayBindGateway([this.req3, this.next]);
      // 单个分配
    } else if (!this.isMulti && this.isDivideConV) {
      this.api.device.updateCompany([this.req5, this.next]);
      // 批量新增
    } else if (this.isMulti && this.isMultiAddConV) {
      this.req.alarmMethodNos = [];
      this.req.alarmUsers = [];
      this.req.model = 1;
      this.api.device.batchAddSensor([this.req, this.next]);
      // 批量绑定
    } else if (this.isMulti && this.isBindConV) {
      this.req3.bindDeviceAddressVos = [];
      this.req3.pDeviceNo = this.req3.device.deviceNo;
      this.req3.device = null;
      this.data.map((one) => {
        if (one.checked) {
          this.req3.bindDeviceAddressVos.push({deviceNo: one.deviceNo, address: Number(this.req3.address) + one.cI});
        }
      });
      this.api.device.batchBindDevice([this.req3, this.next]);
      // 批量分配
    } else if (this.isMulti && this.isDivideConV) {
      this.req5.devices = [];
      this.data.map((one) => {
        if (one.checked) {
          this.req5.devices.push(one.deviceNo);
        }
      });
      this.api.device.batchUpdateCompany([this.req5, this.next]);
    }
  }

  deleteConfirm(one) {
    this.modalService.confirm({
      nzTitle  : '<i> </i>',
      nzContent: '<b>' + this.translate.instant('confirmDeleteThisRecord') + '</b>',
      nzOnOk   : () => this.delete(one)
    });
  }

  delete(one) {
    this.isLoading = true;
    this.api.device.delDevice([{deviceNo: one.deviceNo}, this.next]);
  }

  // 批量的方法
  allUpdate() {
    this.isMulti = true;
    this.isUpdate = true;
  }

  multiAdd() {
    this.isUpdate = false;
    this.isMulti = true;
    this.req = {};
    this.try = {
      trans: {startSN: 'startSN', num: 'addNum', type: 'type', companyNo: 'selectCompany', topicModelNo: 'topicModel'},
      field: ['startSN', 'num', 'type', 'companyNo', 'topicModelNo']
    };
    this.isMultiAddConV = true;
  }

  allDivide() {
    this.isMulti = true;
    this.req5 = {};
    this.try = {
      trans: {companyNo: 'selectCompany', influxdbDatabaseNo: 'selectCompanyInfluxDBTable'},
      field: ['companyNo', 'influxdbDatabaseNo']};
    this.isDivideConV = true;
  }

  allBind() {
    this.isMulti = true;
    this.req3 = {};
    this.selectedGateways = [];
    this.try = {
      trans: {address: 'bindStartTongxinAddr'},
      field: ['address'],
      num: ['address']
    };
    this.isBindConV = true;
  }

  allDelete() {
    this.modalService.confirm({
      nzTitle  : '<i></i>',
      nzContent: '<b>' + this.translate.instant('confirmDeleteSelectedRecord') + '</b>',
      nzOnOk   : () => this.deleteAll()
    });
  }

  deleteAll() {
    const obj = [];
    this.data.map((one) => {
      if (one.checked) {
        obj.push(one.deviceNo);
      }
    });
    this.isLoading = true;
    this.api.device.batchDelDevice([obj, this.next]);
  }

  allUnbind() {
    this.modalService.confirm({
      nzTitle  : '<i></i>',
      nzContent: '<b>' + this.translate.instant('confirmUnbindSelectedRecord') + '</b>',
      nzOnOk   : () => this.unbindAll()
    });
  }

  unbindAll() {
    const obj = [];
    this.data.map((one) => {
      if (one.checked) {
        obj.push(one.deviceNo);
      }
    });
    this.isLoading = true;
    this.api.device.batchUnBindDevice([obj, this.next]);
  }

  toggleOn(one) {
    one.isLoading = true;
    this.api.device.mountUninstallDevice([{deviceNo: one.deviceNo, state: one.state === 0 ? 1 : 0}, (data) => {
      if (data && data.code === 1) {
        one.state = one.state === 0 ? 1 : 0;
        this.message.success(data && data.message ? data.message : '操作成功');
      } else {
        this.message.error(data && data.message ? data.message : '操作失败');
      }
      one.isLoading = false;
    }]);
  }
}
