import {Injectable, OnInit} from '@angular/core';
import {CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {PermissionService} from './permission';
import {MenuService} from './menu';

@Injectable()
export class AuthService implements CanActivateChild, OnInit {
  public login = false;
  private permissionR = '';

  isLogin = false;

  constructor(private router: Router, private menu: MenuService, private permission: PermissionService) {
  }

  ngOnInit() {
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const layoutCon = document.getElementById('layoutCon');
    if (layoutCon) {
      layoutCon.scrollTop = 0;
    }
    return this.checkLogin() && this.permissionCheck(state.url);
    // return this.checkLogin();
    // return true;
  }

  // 登录验证,如果有值就返回true.没有就跳转登录
  checkLogin() {
    return true;
    // if (window.sessionStorage.getItem('_AMap_AMap.MapInner')) {
    //   return true;
    // }
    // this.router.navigate(['/extra/login']);
    // return false;
  }

  // 路由跳转权限检测
  permissionCheck(routerUrl) {
    return true;
  }
}
