import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.css']
})
export class TransferComponent implements OnInit, AfterViewInit {

  left: any = [];
  right: any = [];
  listData = [];
  searchLeftKey = '';
  isloading = false;
  _list: any = [];
  constructor() {}
  @Input()
  transferStyle: any = {'width': '450px', 'height': '300px'};
  @Input()
  label = 'title';
  @Input()
  get list(): any {
    return this._list;
  }

  set list(_list: any) {
    this.listData = _list;
    this.initTransfer();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.initTransfer();
  }

  initTransfer() {
    this.searchLeftKey = '';
    const list = this.listData, left = [], right = [];
    if (list.length === 0) {
      this.left = left;
      this.right = right;
    }
    list.map((item, index) => {
      if (!item.direction || item.direction === 'left') {
        if (left.length < 100) {
          left.push(item);
        }
      } else {
        right.push(item);
      }
      if (index === list.length - 1) {
        this.left = left;
        this.right = right;
        setTimeout(() => {
          this.addMore();
        }, 1);
      }
    });
  }

  addMore() {
    const left = document.getElementById('transfer-left');
    left.scrollTop = 0;
    let flag = 0;
    left.onscroll = () => {
      if (left.scrollTop > left.scrollHeight - 400 && flag === 0 ) {
        this.isloading = true;
        flag = 1;
        setTimeout(() => {
          const leftObj = {...this.left};
          const str = JSON.stringify(this.right);
          const list = this.listData.filter((item) => str.indexOf('"' + this.label + '":"' + item[this.label] + '"') <= -1);
          this.left = leftObj.concat(list.filter((item) => item[this.label].indexOf(this.searchLeftKey) > -1).filter((item, index) => index >= this.left.length && index < this.left.length + 100));
          this.isloading = false;
          flag = 0;
        }, 2000);
      }
    };
  }

  moveToLeft(i, one) {
    this.right.splice(i, 1);
    this.left.push(one);
  }

  moveToRight(i, one) {
    this.left.splice(i, 1);
    this.right.push(one);
  }

  searchLeft() {
    const str = JSON.stringify(this.right);
    let list = this.listData.filter((item) => str.indexOf('"' + this.label + '":"' + item[this.label] + '"') <= -1);
    list = list.filter((item) => item[this.label].indexOf(this.searchLeftKey) > -1).filter((item, index) => index < 100);
    this.left = list;
  }

  getSelected() {
    return this.right;
  }

  checkNone() {
    this.right = [];
    this.searchLeft();
  }

  checkAll() {
    this.right = Array.from(new Set(this.right.concat(this.left)));
    this.searchLeft();
  }
}
