import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-select',
  templateUrl: './search-select.component.html'
})
export class SearchSelectComponent implements OnInit {

  @Input() label: any;
  @Input() value: any;
  @Input() optL: any = 'label';
  @Input() optV: any = 'value';
  @Input() nzMode: any = 'default';
  @Input() inModal: any = false;
  @Input() isMust: any = false;
  @Input() readonly: any = false;
  @Input() data: any = [];
  @Output() valueChange = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  onChange() {
    this.valueChange.emit(this.value);
  }
}
