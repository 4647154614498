import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-back',
  templateUrl: './back.component.html',
  styles: []
})
export class BackComponent implements OnInit {

  constructor() { }

  @Input() isLoading = false;
  ngOnInit() {
  }

  back() {
    window.history.back();
  }

}
