import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MenuService {
  constructor(private translate: TranslateService) {}
  private menu = {
    indexManager: '首页',
    dataManager: '数据管理',
    alarmWarning: '报警预警',
    deviceManager: '传感器管理',
    systemManager: '系统设置',
    index: '首页',
    realData: '实时数据',
    historyData: '历史数据',
    dataComparison: '数据对比',
    warning: '预警管理',
    alarm: '报警管理',
  };

  private menuFront = {
    indexManager: 'home',
    dataManager: 'dataManage',
    alarmWarning: 'alarmWarn',
    deviceManager: 'deviceManage',
    systemManager: 'systemSet',
    index: 'home',
    realData: 'actualData',
    historyData: 'historyData',
    dataComparison: 'compare',
    warning: 'warnInfo',
    alarm: 'alarmInfo',
  };

  transform(value: any): any {
    return this.translate.instant(value);
  }

  transformFront(value: any): any {
    return this.menuFront[value];
  }
}
