import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {NgZorroAntdModule} from 'ng-zorro-antd';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {NZ_MESSAGE_CONFIG} from 'ng-zorro-antd';
import {NgxEchartsModule} from 'ngx-echarts';
import {LayoutComponent} from './layout.component';
// 通用组件
import {SearchDateComponent} from '../component/search-date/search-date.component';
import {SearchItemComponent} from '../component/search-item/search-item.component';
import {SearchSelectComponent} from '../component/search-select/search-select.component';
import {TransferComponent} from '../component/transfer/transfer.component';
import {BackComponent} from '../component/back/back.component';

import {LeftmenuComponent} from './leftmenu/leftmenu.component';
import {FooterComponent} from './footer/footer.component';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {HomeComponent} from '../main/home/home.component';
import {LayoutRoutingModule} from '../router.module';
import {RoleComponent} from '../main/role/role.component';
import {UserComponent} from '../main/user/user.component';
import {LogComponent} from '../main/log/log.component';
import {GatewayComponent} from '../main/gateway/gateway.component';
import {GatewaySeeComponent} from '../main/gateway-see/gateway-see.component';
import {AreaMgrComponent} from '../main/area-mgr/area-mgr.component';
import {ModuleMgrComponent} from '../main/module-mgr/module-mgr.component';
import {MqttSetMgrComponent} from '../main/mqtt-set-mgr/mqtt-set-mgr.component';
import {DataSumComponent} from '../main/data-sum/data-sum.component';
import {HistoryDataComponent} from '../main/history-data/history-data.component';
import {CurrentDataComponent} from '../main/current-data/current-data.component';
import {PartMgrComponent} from '../main/part-mgr/part-mgr.component';
import {SetModuleMgrComponent} from '../main/set-module-mgr/set-module-mgr.component';
import {TopicModuleMgrComponent} from '../main/topic-module-mgr/topic-module-mgr.component';
import {SensorMgrComponent} from '../main/sensor-mgr/sensor-mgr.component';
import {SensorDetailComponent} from '../main/sensor-detail/sensor-detail.component';
import {ModuleDetailComponent} from '../main/module-detail/module-detail.component';
import {TopicModuleDetailComponent} from '../main/topic-module-detail/topic-module-detail.component';
import {TopicModuleWayComponent} from '../main/topic-module-way/topic-module-way.component';
import {InfluxdbComponent} from '../main/influxdb/influxdb.component';
import {SensorTopicWayComponent} from '../main/sensor-topic-way/sensor-topic-way.component';
import {CompanyComponent} from '../main/company/company.component';
import {RetentionPolicyMgrComponent} from '../main/retention-policy-mgr/retention-policy-mgr.component';

@NgModule({
  imports: [
    TranslateModule,
    NgxEchartsModule,
    CommonModule,
    NgZorroAntdModule,
    FormsModule,
    LayoutRoutingModule
  ],
  providers: [
    {
      provide: NZ_MESSAGE_CONFIG,
      useValue: {
        nzDuration: 3000,
        nzMaxStack: 7,
        nzPauseOnHover: true,
        nzAnimate: true
      }
    }
  ],
  declarations: [
    LayoutComponent,
    LeftmenuComponent,
    FooterComponent,
    BreadcrumbComponent,
    HomeComponent,
    UserComponent,
    CompanyComponent,
    RoleComponent,
    LogComponent,
    GatewayComponent,
    SearchDateComponent,
    SearchItemComponent,
    SearchSelectComponent,
    TransferComponent,
    GatewaySeeComponent,
    BackComponent,
    AreaMgrComponent,
    ModuleMgrComponent,
    MqttSetMgrComponent,
    DataSumComponent,
    HistoryDataComponent,
    CurrentDataComponent,
    PartMgrComponent,
    SetModuleMgrComponent,
    TopicModuleMgrComponent,
    SensorMgrComponent,
    SensorDetailComponent,
    ModuleDetailComponent,
    TopicModuleDetailComponent,
    TopicModuleWayComponent,
    InfluxdbComponent,
    SensorTopicWayComponent,
    RetentionPolicyMgrComponent
  ]
})
export class LayoutModule {
}
