import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../service/api';
import {NzMessageService, NzModalService} from 'ng-zorro-antd';
import {TranslateService} from '@ngx-translate/core';
import {fadeIn} from '../../animation/fade-in';
import {Router} from '@angular/router';
import {CheckService} from '../../service/check';

@Component({
  selector: 'app-topic-module-mgr',
  templateUrl: './topic-module-mgr.component.html',
  styles: [],
  animations: [fadeIn]
})
export class TopicModuleMgrComponent implements OnInit {

  req: any = {};
  req2;
  data = [];
  isLoading = false;
  isAddConV = false;
  isSaveOther = false;
  isUpdate = false;
  topicModuleName = '';
  try = {};
  companys = [];

  constructor(private api: ApiService, private message: NzMessageService, private modalService: NzModalService,
              private translate: TranslateService, private router: Router, private ck: CheckService) {
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.getData();
    this.getCompanys();
  }

  getData() {
    this.isLoading = true;
    this.api.topicModel.getAll(['', this.next]);
  }

  getCompanys() {
    this.isLoading = true;
    this.api.company.getAll(['', (data) => {
      if (data && data.constructor === Array) {
        this.companys = data;
      } else {
        this.message.error(data && data.message ? data.message : '获取数据失败');
      }
      this.isLoading = false;
    }]);
  }

  onSearch() {
    this.getData();
  }

  onGetAll() {
    this.topicModuleName = '';
    this.getData();
  }

  next = (data) => {
    if (data && data.constructor === Array) {
      this.data = data.filter(one => one.topicModelName.indexOf(this.topicModuleName) > -1);
    } else if (data && data.code === 1) {
      this.message.success(data.message ? data.message : '操作成功');
      this.isAddConV = false;
      this.isSaveOther = false;
      this.init();
    } else if (data && data.code === 0) {
      this.message.error(data.message ? data.message : '操作失败');
    } else {
      this.message.error(data && data.message ? data.message : '获取数据失败');
    }
    this.isLoading = false;
  }

  toDetail(one) {
    this.router.navigate(['/app/deviceCenter/topicModuleMgr/topicModuleDetail'],
      {queryParams: {topicModelNo: one.topicModelNo, topicModuleName: one.topicModuleName}});
  }

  saveOther(one) {
    this.isSaveOther = true;
    this.req2 = {
      companyNo: one.companyNo,
      newTopicModelName: '',
      topicModelNo: one.topicModelNo
    };
    this.try = {
      trans: {newTopicModelName: 'moduleName'},
      field: ['newTopicModelName', 'topicModelNo']};
  }

  add() {
    this.isUpdate = false;
    this.req = {};
    this.isAddConV = true;
    this.try = {
      trans: {newTopicModelName: 'moduleName'},
      field: ['topicModelName', 'prefixName']};
  }

  update(one) {
    this.isUpdate = true;
    this.req = {...one};
    this.isAddConV = true;
    this.try = {
      trans: {newTopicModelName: 'moduleName', front: 'prefixName'},
      field: ['topicModelNo', 'topicModelName', 'prefixName']};
  }

  cancel(v) {
    this[v] = false;
  }

  save(v) {
    this.isLoading = true;
    if (!this.ck.formCheck(this[v], this.try)) {
      this.isLoading = false;
      return;
    }
    if (this.isUpdate && this.isAddConV) {
      this.api.topicModel.updateTopic([this.req, this.next]);
    } else if (!this.isUpdate && this.isAddConV) {
      this.req.addTopicModelForInfoVos = [];
      this.api.topicModel.addTopicModel([this.req, this.next]);
    } else if (this.isSaveOther) {
      this.api.topicModel.saveAsTopicModel([this.req2, this.next]);
    }
  }

  deleteConfirm(one) {
    this.modalService.confirm({
      nzTitle: '<i> </i>',
      nzContent: '<b>' + this.translate.instant('confirmDeleteThisRecord') + '</b>',
      nzOnOk: () => this.delete(one)
    });
  }

  delete(one) {
    this.isLoading = true;
    this.api.topicModel.delTopicModel([{topicModelNo: one.topicModelNo}, this.next]);
  }
}
