import {Component, TemplateRef, ViewChild, OnInit} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {ApiService} from '../service/api';
import {NzMessageService, zh_CN, en_US, NzI18nService} from 'ng-zorro-antd';
import {LeftmenuComponent} from './leftmenu/leftmenu.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  isCollapsed = false;
  triggerTemplate = null;
  title;
  bcShow = true;
  selectedItem;
  userName;
  @ViewChild('trigger') customTrigger: TemplateRef<void>;
  @ViewChild('leftmenu') leftmenu: LeftmenuComponent;

  constructor(private router: Router, private api: ApiService, private message: NzMessageService,
              private translate: TranslateService, private nzI18nService: NzI18nService) {
  }

  // custom trigger can be TemplateRef
  changeTrigger(): void {
    this.triggerTemplate = this.customTrigger;
  }

  changeCollapsed() {
    this.leftmenu.collapseChange(!this.isCollapsed);
    this.isCollapsed = !this.isCollapsed;
  }

  ngOnInit() {
    let hash = window.location.hash;
    if (hash.indexOf('?') > -1) {
      hash = hash.slice(0, hash.indexOf('?'));
    }
    if (hash !== '#/app') {
      this.bcShow = true;
      this.title = hash.replace(/#\/app\//g, '').split('/');
    } else {
      this.bcShow = false;
    }
    this.selectedItem = hash.replace(/#\/app\//g, '')
      .replace(/\/detail/g, '/dataMonitoring/')
      .replace(/\/oldData/g, '/dataMonitoring/').split('/');
    this.router.events
      .subscribe((event: NavigationEnd) => {
        if (event instanceof NavigationEnd) {
          let hashValue = window.location.hash;
          if (hashValue.indexOf('?') > -1) {
            hashValue = hashValue.slice(0, hashValue.indexOf('?'));
          }
          if (hashValue !== '#/app') {
            this.bcShow = true;
            this.title = hashValue.replace(/#\/app\//g, '').split('/');
          } else {
            this.bcShow = false;
          }
          this.selectedItem = hashValue.replace(/#\/app\//g, '')
            .replace(/\/detail/g, '/dataMonitoring')
            .replace(/\/oldData/g, '/dataMonitoring').split('/');
        }
      });

    // 获取登录用户信息
    // this.api.user.getSelfInfo(['', (data: any) => {
    //   this.userName = data.userName;
    // }]);
  }

  loginOut() {
    this.api.login.logout(['', (data: any) => {
      const message = data.message ? data.message : this.translate.instant('loginOutSuccess');
      this.message.success(message);
      window.sessionStorage.clear();
      this.router.navigate(['/extra/login']);
    }]);
  }
}
