import {Injectable} from '@angular/core';
import {NzMessageService} from 'ng-zorro-antd';
import {isUndefined} from 'util';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class CheckService {
  private flag = 0;
  private trans = {};

  constructor(private message: NzMessageService, private translate: TranslateService) {
  }

  formCheck(data, req) {
    this.flag = 0;
    this.trans = req.trans;
    const fieldStr = '-' + req.field.join('-') + '-';
    if (req.field) {
      req.field.filter((item) => this.checkField(fieldStr, data, item));
      if (this.flag) {
        return false;
      }
    }

    if (req.time) {
      req.time.filter((item) => this.checkTime(fieldStr, data, item));
      if (this.flag) {
        return false;
      }
    }

    if (req.tel) {
      req.tel.filter((item) => this.checkTel(fieldStr, data, item));
      if (this.flag) {
        return false;
      }
    }

    if (req.money) {
      req.money.filter((item) => this.checkMoney(fieldStr, data, item));
      if (this.flag) {
        return false;
      }
    }

    if (req.num) {
      req.num.filter((item) => this.checkNum(fieldStr, data, item));
      if (this.flag) {
        return false;
      }
    }

    if (req.float2) {
      req.float2.filter((item) => this.checkFloat2(fieldStr, data, item));
      if (this.flag) {
        return false;
      }
    }

    if (req.canFloat2) {
      req.canFloat2.filter((item) => this.checkCanFloat2(fieldStr, data, item));
      if (this.flag) {
        return false;
      }
    }

    if (req.eng) {
      req.eng.filter((item) => this.checkEng(fieldStr, data, item));
      if (this.flag) {
        return false;
      }
    }

    return true;
  }

  private checkField(fieldStr, data, field) {
    if (this.flag === 1) {
      return false;
    }
    if ((data[field] === '' || isUndefined(data[field]) || data[field] === null) && fieldStr.indexOf('-' + field + '-') >= -1) {
      this.message.warning(this.translate.instant(this.trans[field]) + '为必填字段, 请检查');
      this.flag = 1;
      return false;
    } else {
      return true;
    }
  }

  private checkTime(fieldStr, data, time) {
    if (this.flag === 1) {
      return false;
    }
    if ((data[time] === '' || isUndefined(data[time]) || data[time] === null) && fieldStr.indexOf('-' + time + '-') <= -1) {
      return true;
    }
    if (data[time] && new Date(data[time]).getTime() > 0 && new Date(data[time]).getTime().toString().length === 13) {
      return true;
    } else {
      this.message.warning(this.translate.instant(this.trans[time]) + '格式填写错误, 应填入正确时间');
      this.flag = 1;
      return false;
    }
  }

  private checkTel(fieldStr, data, tel) {
    if (this.flag === 1) {
      return false;
    }
    if ((data[tel] === '' || isUndefined(data[tel]) || data[tel] === null) && fieldStr.indexOf('-' + tel + '-') <= -1) {
      return true;
    }
    const reg = new RegExp('^1\\d{10}$');
    if (data[tel].length !== 11 || !reg.test(data[tel])) {
      this.message.warning(this.translate.instant(this.trans[tel]) + '格式填写错误, 应填入正确11位手机号');
      this.flag = 1;
      return false;
    } else {
      return true;
    }
  }

  private checkMoney(fieldStr, data, money) {
    if (this.flag === 1) {
      return false;
    }
    if ((data[money] === '' || isUndefined(data[money]) || data[money] === null) && fieldStr.indexOf('-' + money + '-') <= -1) {
      return true;
    }
    if (parseFloat(data[money]).toFixed(2) === data[money] && parseInt(data[money], 10).toString().length < 8) {
      return true;
    } else {
      this.message.warning(this.translate.instant(this.trans[money]) + '格式填写错误, 应填入数字金额');
      this.flag = 1;
      return false;
    }
  }

  private checkNum(fieldStr, data, num) {
    if (this.flag === 1) {
      return false;
    }
    if ((data[num] === '' || isUndefined(data[num]) || data[num] === null) && fieldStr.indexOf('-' + num + '-') <= -1) {
      return true;
    }
    const reg = new RegExp('^[0-9.]*$');
    if (reg.test(data[num])) {
      return true;
    } else {
      this.message.warning(this.translate.instant(this.trans[num]) + '格式填写错误, 应填入数值');
      this.flag = 1;
      return false;
    }
  }

  private checkFloat2(fieldStr, data, float2) {
    if (this.flag === 1) {
      return false;
    }
    if ((data[float2] === '' || isUndefined(data[float2]) || data[float2] === null) && fieldStr.indexOf('-' + float2 + '-') <= -1) {
      return true;
    }
    if (parseFloat(data[float2]).toFixed(2).toString() === data[float2]) {
      return true;
    } else {
      this.message.warning(this.translate.instant(this.trans[float2]) + '格式填写错误, 应填入两位小数');
      this.flag = 1;
      return false;
    }
  }


  private checkCanFloat2(fieldStr, data, canFloat2) {
    if (this.flag === 1) {
      return false;
    }
    if ((data[canFloat2] === '' || isUndefined(data[canFloat2]) || data[canFloat2] === null) &&
      fieldStr.indexOf('-' + canFloat2 + '-') <= -1) {
      return true;
    }
    const reg = new RegExp('^[0-9]*([.][0-9]{0,2})?$');
    if (reg.test(data[canFloat2])) {
      return true;
    } else {
      this.message.warning(this.translate.instant(this.trans[canFloat2]) + '格式填写错误, 应填入两位小数');
      this.flag = 1;
      return false;
    }
  }

  private checkEng(fieldStr, data, eng) {
    if (this.flag === 1) {
      return false;
    }
    if ((data[eng] === '' || isUndefined(data[eng]) || data[eng] === null) && fieldStr.indexOf('-' + eng + '-') <= -1) {
      return true;
    }
    const reg = new RegExp('^[a-zA-Z0-9]*$');
    if (reg.test(data[eng])) {
      return true;
    } else {
      this.message.warning(this.translate.instant(this.trans[eng]) + '格式填写错误, 应填入英文或数字');
      this.flag = 1;
      return false;
    }
  }
}
