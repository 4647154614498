import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {zh_CN, en_US, NzI18nService} from 'ng-zorro-antd';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(private translateService: TranslateService, private nzI18nService: NzI18nService) {}
  ngOnInit() {
    // --- set i18n begin ---
    this.translateService.addLangs(['zh', 'en', 'jpn']);
    const lan = localStorage.getItem('default_language');
    if (lan) {
      this.translateService.setDefaultLang(lan);
      this.translateService.use(lan);
      if (lan === 'zh') {
        this.nzI18nService.setLocale(zh_CN);
      } else {
        this.nzI18nService.setLocale(en_US);
      }
    } else {
      this.translateService.setDefaultLang('zh');
      this.translateService.use('zh');
      this.nzI18nService.setLocale(zh_CN);
    }
    // --- set i18n end ---
  }
}
