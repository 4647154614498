import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mqtt-set-mgr',
  templateUrl: './mqtt-set-mgr.component.html',
  styles: []
})
export class MqttSetMgrComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
