import {HttpService} from './http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd';
import {environment} from '../../environments/environment';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ApiService {
  constructor(private http: HttpService, private router: Router,
              private message: NzMessageService, private translate: TranslateService) {
  }

  tokenLoseflag = 0;
  public url = environment.baseUrl;

  public scence = {
    getAll: (data) => this.trans('get', '/system/scence/getAll', data),
    getByCompany: (data) => this.trans('post', '/system/scence/getByCompany', data),
  };

  public loginlog = {
    getAll: (page, rows, data) => this.trans('get', '/system/loginlog/getAll?page=' + page + '&rows=' + rows, data),
    getByTime: (page, rows, data) => this.trans('post', '/system/loginlog/getByTime?page=' + page + '&rows=' + rows, data),
  };

  public login = {
    loginForToken: (data) => this.trans('post', '/system/login/loginForToken', data),
    logout: (data) => this.trans('get', '/system/login/logout', data),
  };

  public permission = {
    getAll: (data) => this.trans('get', '/system/permission/getAll', data),
    getPermissionByRoleNo: (data) => this.trans('post', '/system/permission/getPermissionByRoleNo', data),
  };

  public role = {
    addRole: (data) => this.trans('post', '/system/role/addRole', data),
    deleteRole: (data) => this.trans('post', '/system/role/deleteRole', data),
    getAll: (data) => this.trans('get', '/system/role/getAll', data),
    updateRole: (data) => this.trans('post', '/system/role/updateRole', data),
  };

  public user = {
    addUser: (data) => this.trans('post', '/system/user/addUser', data),
    deleteUser: (data) => this.trans('post', '/system/user/deleteUser', data),
    getAll: (data) => this.trans('get', '/system/user/getAll', data),
    getByUserNameForLike: (data) => this.trans('post', '/system/user/getByUserNameForLike', data),
    getSelfInfo: (data) => this.trans('get', '/system/user/getSelfInfo', data),
    updateSelfPassword: (data) => this.trans('post', '/system/user/updateSelfPassword', data),
    updateUser: (data) => this.trans('post', '/system/user/updateUser', data),
  };

  public area = {
    getAll: (data) => this.trans('get', '/system/area/getAll', data),
    getByCity: (data) => this.trans('post', '/system/area/getByCity', data),
  };

  public city = {
    getByProvince: (data) => this.trans('post', '/system/city/getByProvince', data),
  };

  public company = {
    addCompany: (data) => this.trans('post', '/system/company/addCompany', data),
    delCompany: (data) => this.trans('post', '/system/company/delCompany', data),
    getAll: (data) => this.trans('get', '/system/company/getAll', data),
    updateCompany: (data) => this.trans('post', '/system/company/updateCompany', data),
    updateCompanyPermission: (data) => this.trans('post', '/system/company/updateCompanyPermission', data),
  };

  public province = {
    getAll: (data) => this.trans('get', '/system/province/getAll', data),
  };

  public probesModel = {
    addProbesModel: (data) => this.trans('post', '/device/probesModel/addProbesModel', data),
    delProbesModel: (data) => this.trans('post', '/device/probesModel/delProbesModel', data),
    getAll: (data) => this.trans('get', '/device/probesModel/getAll', data),
    saveAsProbesModel: (data) => this.trans('post', '/device/probesModel/saveAsProbesModel', data),
    updateProbesModel: (data) => this.trans('post', '/device/probesModel/updateProbesModel', data),
  };

  public probesModelInfo = {
    addProbesModelInfo: (data) => this.trans('post', '/device/probesModelInfo/addProbesModelInfo', data),
    delProbesModelInfo: (data) => this.trans('post', '/device/probesModelInfo/delProbesModelInfo', data),
    getAll: (data) => this.trans('post', '/device/probesModelInfo/getAll', data),
    updateProbesModelInfo: (data) => this.trans('post', '/device/probesModelInfo/updateProbesModelInfo', data),
  };

  public topicModelInfo = {
    addTopicModelInfo: (data) => this.trans('post', '/device/topicModelInfo/addTopicModelInfo', data),
    delTopicModelInfo: (data) => this.trans('post', '/device/topicModelInfo/delTopicModelInfo', data),
    deployTopicModelInfo: (data) => this.trans('post', '/device/topicModelInfo/deployTopicModelInfo', data),
    getAll: (data) => this.trans('post', '/device/topicModelInfo/getAll', data),
    updateTopicModelInfo: (data) => this.trans('post', '/device/topicModelInfo/updateTopicModelInfo', data),
  };

  public topicModel = {
    addTopicModel: (data) => this.trans('post', '/device/topicModel/addTopicModel', data),
    delTopicModel: (data) => this.trans('post', '/device/topicModel/delTopicModel', data),
    getAll: (data) => this.trans('get', '/device/topicModel/getAll', data),
    saveAsTopicModel: (data) => this.trans('post', '/device/topicModel/saveAsTopicModel', data),
    updateTopic: (data) => this.trans('post', '/device/topicModel/updateTopic', data),
  };

  public topicConfigModel = {
    addTopicConfigModel: (data) => this.trans('post', '/device/topicConfigModel/addTopicConfigModel', data),
    delProbesModelInfo: (data) => this.trans('post', '/device/topicConfigModel/delProbesModelInfo', data),
    getAll: (data) => this.trans('post', '/device/topicConfigModel/getAll', data),
    updateTopicConfigModel: (data) => this.trans('post', '/device/topicConfigModel/updateTopicConfigModel', data),
  };

  public topic = {
    addTopic: (data) => this.trans('post', '/device/topic/addTopic', data),
    delTopic: (data) => this.trans('post', '/device/topic/delTopic', data),
    getByDeviceNo: (data) => this.trans('post', '/device/topic/getByDeviceNo', data),
    saveAsTopicModel: (data) => this.trans('post', '/device/topic/saveAsTopicModel', data),
    updateTopic: (data) => this.trans('post', '/device/topic/updateTopic', data),
  };

  public device = {
    addGateway: (data) => this.trans('post', '/device/device/addGateway', data),
    addSensorOrBlend: (data) => this.trans('post', '/device/device/addSensorOrBlend', data),
    batchAddGateway: (data) => this.trans('post', '/device/device/batchAddGateway', data),
    batchAddSensor: (data) => this.trans('post', '/device/device/batchAddSensor', data),
    batchBindDevice: (data) => this.trans('post', '/device/device/batchBindDevice', data),
    batchDelDevice: (data) => this.trans('post', '/device/device/batchDelDevice', data),
    batchUnBindDevice: (data) => this.trans('post', '/device/device/batchUnBindDevice', data),
    batchUpdateCompany: (data) => this.trans('post', '/device/device/batchUpdateCompany', data),
    delDevice: (data) => this.trans('post', '/device/device/delDevice', data),
    deploySensor: (data) => this.trans('post', '/device/device/deploySensor', data),
    gatewayBindGateway: (data) => this.trans('post', '/device/device/bindDevice', data),
    getSensorByMaxDevice: (data) => this.trans('post', '/device/device/getSensorByMaxDevice', data),
    getAll: (page, rows, data) => this.trans('post', '/device/device/getAll?page=' + page + '&rows=' + rows, data),
    getByTerm: (page, rows, data) => this.trans('post', '/device/device/getByTerm?page=' + page + '&rows=' + rows, data),
    mountUninstallDevice: (data) => this.trans('post', '/device/device/mountUninstallDevice', data),
    unBindDevice: (data) => this.trans('post', '/device/device/unBindDevice', data),
    updateCompany: (data) => this.trans('post', '/device/device/updateCompany', data),
    updateGateway: (data) => this.trans('post', '/device/device/updateGateway', data),
    updateSensor: (data) => this.trans('post', '/device/device/updateSensor', data),
  };

  public influxdbTables = {
    addInfluxdbTables: (data) => this.trans('post', '/device/influxdbTables/addInfluxdbTables', data),
    delInfluxdbTables: (data) => this.trans('post', '/device/influxdbTables/delInfluxdbTables', data),
    getAll: (data) => this.trans('get', '/device/influxdbTables/getAll', data),
    getByCompanyNo: (data) => this.trans('post', '/device/influxdbTables/getByCompanyNo', data),
    updateInfluxdbTables: (data) => this.trans('post', '/device/influxdbTables/updateInfluxdbTables', data),
  };

  public influxdbPolicy = {
    addInfluxdbPolicy: (data) => this.trans('post', '/device/influxdbPolicy/addInfluxdbPolicy', data),
    updateInfluxdbPolicy: (data) => this.trans('post', '/device/influxdbPolicy/updateInfluxdbPolicy', data),
    getPolicyByInfluxdb: (data) => this.trans('post', '/device/influxdbPolicy/getPolicyByInfluxdb', data),
    delInfluxdbPolicy: (data) => this.trans('post', '/device/influxdbPolicy/delInfluxdbPolicy', data),
    getAllPolicyByCompany: (data) => this.trans('post', '/device/influxdbPolicy/getAllPolicyByCompany', data),
    updateDefaultPolicy: (data) => this.trans('post', '/device/influxdbPolicy/updateDefaultPolicy', data),
  };

  public probes = {
    addProbes: (data) => this.trans('post', '/device/probes/addProbes', data),
    delAProbes: (data) => this.trans('post', '/device/probes/delAProbes', data),
    getAll: (data) => this.trans('post', '/device/probes/getAll', data),
    saveAsProbesModel: (data) => this.trans('post', '/device/probes/saveAsProbesModel', data),
    getProbesByGateway: (data) => this.trans('post', '/device/probes/getProbesByGateway', data),
    updateProbes: (data) => this.trans('post', '/device/probes/updateProbes', data),
  };

  public data = {
    getHisData: (data) => this.trans('post', '/data/getHisData', data),
    getRealData: (data) => this.trans('post', '/data/getRealData', data),
    getStatus: (data) => this.trans('post', '/data/getStatus', data),
    updateControl: (data) => this.trans('post', '/data/updateControl', data),
  };

  private trans(type, addr, data) {
    this.sendHttpReq(type, addr, data[0], data[1], data[2], data[3]);
  }

  private sendHttpReq(type, addr, reqB, next, error, complete) {
    let nextFunc;
    if (addr === '/login/dologin') {
      nextFunc = (data: any) => {
        next(data);
      };
    } else {
      nextFunc = (data: any) => {
        if (this.http.handleToken(data)) {
          next(data);
        } else {
          if (!this.tokenLoseflag) {
            this.message.error(this.translate.instant('loginOutValidityTips'));
            this.http.toLoginPage(2000);
            this.tokenLoseflag = 1;
            setTimeout(() => {
              this.tokenLoseflag = 0;
            }, 5000);
          }
        }
      };
    }
    if (!error) {
      error = (err) => {
        let data;
        if (err && err.error && err.error.status) {
          if (err.error.status === 401) {
            data = {code: 0, message: this.translate.instant('noLoginTips')};
            this.router.navigate(['/extra/login']);
          } else {
            data = {code: 0, message: this.translate.instant('wrongServerWithCode') + err.error.status};
          }
        } else {
          data = {code: 0, message: this.translate.instant('wrongServer')};
        }
        next(data);
      };
    }
    if (type === 'get') {
      this.http.get(this.url + addr, nextFunc, error, complete);
    } else {
      this.http.post(this.url + addr, reqB, nextFunc, error, complete);
    }
  }
}
