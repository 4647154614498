import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../service/api';
import {NzMessageService, NzModalService} from 'ng-zorro-antd';
import {TranslateService} from '@ngx-translate/core';
import {CheckService} from '../../service/check';
import {fadeIn} from '../../animation/fade-in';
import {Router} from '@angular/router';

@Component({
  selector: 'app-influxdb',
  templateUrl: './influxdb.component.html',
  styles: [],
  animations: [fadeIn]
})
export class InfluxdbComponent implements OnInit {

  req: any = {};
  data: any = [];
  isLoading = false;
  isVisible = false;
  isUpdate = false;
  companyNo = '';
  companys = [];

  constructor(private api: ApiService, private message: NzMessageService, private translate: TranslateService,
              private modalService: NzModalService, private ck: CheckService, private router: Router) {
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.getData();
    this.getCompanys();
  }

  getCompanys() {
    this.isLoading = true;
    this.api.company.getAll(['', (data) => {
      if (data.constructor === Array) {
        this.companys = data;
      } else {
        this.message.error(data && data.message ? data.message : '获取数据失败');
      }
      this.isLoading = false;
    }]);
  }

  next = (data) => {
    if (data && data.constructor === Array) {
      this.data = data;
    } else if (data && data.code === 1) {
      this.message.success(data.message ? data.message : '操作成功');
      this.init();
      this.isVisible = false;
    } else if (data && data.code === 0) {
      this.message.error(data.message ? data.message : '操作失败');
    } else {
      this.message.error(data && data.message ? data.message : '获取数据失败');
    }
    this.isLoading = false;
  }

  getData() {
    this.isLoading = true;
    this.api.influxdbTables.getAll(['', this.next]);
  }

  onSearch() {
    this.isLoading = true;
    this.api.influxdbTables.getByCompanyNo([{companyNo: this.companyNo}, this.next]);
  }

  onGetAll() {
    this.companyNo = '';
    this.getData();
  }

  add() {
    this.isUpdate = false;
    this.req = {};
    this.isVisible = true;
  }

  onPolicyMgr(one) {
    this.router.navigate(['/app/systemMgr/influxdbMgr/retentionPolicyMgr'],
      {queryParams: {influxdbDatabaseNo: one.influxdbDatabaseNo,
          defaultDatabase: one.defaultDatabase, companyNo: one.companyNo}});
  }
  edit(one) {
    this.isUpdate = true;
    this.req = {...one};
    this.isVisible = true;
  }

  save() {
    this.isLoading = true;
    if (this.isUpdate) {
      this.api.influxdbTables.updateInfluxdbTables([this.req, this.next]);
    } else {
      this.api.influxdbTables.addInfluxdbTables([this.req, this.next]);
    }
  }

  cancel() {
    this.isVisible = false;
  }

  deleteConfirm(one) {
    this.modalService.confirm({
      nzTitle  : '<i></i>',
      nzContent: '<b>' + this.translate.instant('confirmDeleteThisRecord') + '</b>',
      nzOnOk   : () => this.delete(one)
    });
  }

  delete(one) {
    this.api.influxdbTables.delInfluxdbTables([{influxdbDatabaseNo: one.influxdbDatabaseNo}, this.next]);
  }
}
