import { Injectable, OnInit } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/index';

@Injectable()
export class AuthInterceptor implements HttpInterceptor, OnInit {
  constructor() {
  }

  token: string;

  ngOnInit() {
  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // 这里的拦截了所有的请求,并且加上了token和语言
    // const authHeader = '';
    // 每次都获取最新的token
    this.token = window.sessionStorage.getItem('_AMap_AMap.MapInner') || '';
    let lan = localStorage.getItem('witium-default-language') || '';
    if (lan === 'zh') {
      lan = 'cn';
    }
    lan = lan || 'cn';
    const authReq = req.clone({headers: req.headers.set('AccountToken', this.token).set('AccountLanguage', lan)});
    // 额外加上语言识别
    // const authLanguage = authReq.clone({headers: authReq.headers.set('AccountLanguage', this.language.authLangauge)})
    return next.handle(authReq);

  }
}
