import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../service/api';
import {NzMessageService, NzModalService} from 'ng-zorro-antd';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CheckService} from '../../service/check';
import {fadeIn} from '../../animation/fade-in';

@Component({
  selector: 'app-part-mgr',
  templateUrl: './part-mgr.component.html',
  styles: [],
  animations: [fadeIn]
})
export class PartMgrComponent implements OnInit {

  constructor(private api: ApiService, private message: NzMessageService, private router: Router,
              private modalService: NzModalService, private translate: TranslateService,
              private ck: CheckService) { }

  isLoading = false;
  data = [];
  ngOnInit() {
    this.init();
  }

  init() {
    this.getData();
  }

  next = (data) => {
    if (data.constructor === Array) {
      this.data = data;
    } else {
      this.message.error(data.message ? data.message : '获取数据失败');
    }
  }

  getData() {
    this.isLoading = false;
    this.api.scence.getAll(['', this.next]);
  }
}
