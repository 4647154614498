import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-date',
  templateUrl: './search-date.component.html'
})
export class SearchDateComponent implements OnInit {

  @Input() label: any;
  v: any;
  date: any;
  @Input()
  get value(): any {
    return this.v;
  }
  set value(v: any) {
    this.initDate(v);
  }
  @Input() showTime: any = false;
  @Input() inModal: any = false;
  @Input() isMust: any = false;
  @Input() readonly: any = false;
  @Output() valueChange = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  initDate(date) {
    date = Number(date);
    if (!isNaN(date)) {
      this.date = new Date(date);
    } else {
      this.date = '';
    }
  }

  onChange() {
    const value = new Date(this.date).getTime();
    this.valueChange.emit(value);
  }
}
