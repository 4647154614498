import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../service/api';
import {NzMessageService, NzModalService} from 'ng-zorro-antd';
import {ActivatedRoute, Router} from '@angular/router';
import {fadeIn} from '../../animation/fade-in';
import {TranslateService} from '@ngx-translate/core';
import {CheckService} from '../../service/check';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-sensor-detail',
  templateUrl: './sensor-detail.component.html',
  styleUrls: ['./sensor-detail.component.scss'],
  animations: [fadeIn]
})
export class SensorDetailComponent implements OnInit {

  isLoading = false;
  deviceData = [];
  topicOption = [];
  deviceNo = '';
  name = '';
  req: any = {};
  req2: any = {};
  req3: any = {};
  req4 = {};
  req5: any = {};
  isUC = false;
  isUC2 = false;
  isMC = false;
  isToSee = false;
  ToSeeList: any;
  isUpdate = false;
  probsModels = [];
  topicModels = [];
  actualData = [];
  histData = [];
  toSeeData = [];
  wayData = [];
  selectTime3 = 24;
  option1: any = {};
  isClose = false;
  isSaveOther = false;
  companyNo = '';
  try = {};
  sn;
  type;
  chartOption;
  historyDate = [];
  historyData = [];
  hisDataBeforeNow;
  hisDataBeforeTime;
  hisTopicName;
  hisAlias;
  hisDevInfo;
  selectedTopicValue;

  policyList = [];

  constructor(private api: ApiService, private message: NzMessageService, private router: Router, private ck: CheckService,
              private ac: ActivatedRoute, private modalService: NzModalService, private translate: TranslateService) {
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.ac.queryParams.subscribe((e) => {
      if (e.deviceNo) {
        this.deviceNo = e.deviceNo;
        this.sn = e.sn;
        this.type = e.type;
        this.name = e.name;
        this.companyNo = e.companyNo;
        this.getTopicData();
        this.getProbsModels();
        this.getTopicModels();
        this.getProbs();
        this.getCurrent();
      }
    });
    this.topicOption = [];
    this.getAllPolicies();
  }

  next = (data) => {
    if (data && data.code === 1) {
      this.message.success(data && data.message ? data.message : '操作成功');
      this.cancel();
      this.getTopicData();
      this.getProbsModels();
      this.getTopicModels();
      this.getProbs();
      this.getCurrent();
    } else if (data && data.code === 0) {
      this.message.error(data && data.message ? data.message : '操作失败');
    } else {
      this.message.error(data && data.message ? data.message : '获取数据失败');
    }
    this.isLoading = false;
  }

  getProbsModels() {
    this.isLoading = true;
    this.api.probesModel.getAll(['', (data) => {
      if (data && data.constructor === Array) {
        this.probsModels = data;
      } else {
        this.message.error(data && data.message ? data.message : '获取数据失败');
      }
      this.isLoading = false;
    }]);
  }

  getTopicData() {
    this.isLoading = true;
    this.api.topic.getByDeviceNo([{deviceNo: this.deviceNo}, (data) => {
      if (data && data.constructor === Array) {
        this.deviceData = data;
        // 在主题的下拉选项中，过滤掉topicType=4(REPORT) 的主题
        this.updateTopicSelection();
      } else {
        this.message.error(data && data.message ? data.message : '获取数据失败');
      }
      this.isLoading = false;
    }]);
  }

  getTopicModels() {
    this.isLoading = true;
    this.api.topicModel.getAll(['', (data) => {
      if (data && data.constructor === Array) {
        this.topicModels = data;
      } else {
        this.message.error(data && data.message ? data.message : '获取数据失败');
      }
      this.isLoading = false;
    }]);
  }

  getProbs() {
    // this.isLoading = true;
    // this.api.probes.getProbesByGateway([{deviceNo: this.deviceNo}, (data) => {
    //   if (data && data.constructor === Array) {
    //     this.wayData = data;
    //   } else {
    //     this.message.error(data && data.message ? data.message : '获取数据失败');
    //   }
    //   this.isLoading = false;
    // }]);
  }

  getCurrent() {
    this.isLoading = true;
    this.api.data.getRealData([{sn: this.sn, type: this.type }, (data) => {
      if (data && data.constructor === Array) {
        this.actualData = data;
      } else {
        this.message.error(data && data.message ? data.message : '获取数据失败');
      }
      this.isLoading = false;
    }]);
  }
  // frank
  format(timestamp, format) {
    const date = new Date(timestamp);
    const o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds(),
      'q+': Math.floor((date.getMonth() + 3) / 3),
      S: date.getMilliseconds()};
    let formatString = format;
    if (/(y+)/.test(format)) {
      formatString = format.replace(RegExp.$1, (`${date.getFullYear()}`).substr(4 - RegExp.$1.length));
    }
    Object.keys(o).forEach((key) => {
      if (new RegExp(`(${key})`).test(format)) {
        formatString = formatString.replace(RegExp.$1, RegExp.$1.length === 1 ? o[key] : (`00${o[key]}`).substr(`${o[key]}`.length));
      }
      }
      );
    return formatString;
  }


  getAllPolicies() {
    this.isLoading = true;
    this.policyList = [];
    this.api.influxdbPolicy.getAllPolicyByCompany(['', (data) => {
      if (data.constructor === Array) {
        data.forEach(one => {
          this.policyList.push({
            opLevel: one.databaseName + ' : ' + one.retentionPolicyName,
            opValue: one.retentionPolicyNo,
            duration: one.duration
          });
        });
      } else {
        this.message.error(data && data.message ? data.message : '获取数据失败');
      }
      this.isLoading = false;
    }]);
  }

  getHistoryData(): void {
    // this.hisTopicName = this.wayData[0].topicName;
    this.hisTopicName = this.selectedTopicValue;
    this.api.data.getHisData([{
      alias: this.hisDevInfo.alias,
      deviceList: [{sn: this.hisDevInfo.SN, type: this.hisDevInfo.dType}],
      endTime: this.selectTime3 ? new Date().getTime() : this.req3.endTime,
      offset: 0 - new Date().getTimezoneOffset() / 60,
      startTime: this.selectTime3 ? new Date().getTime() - this.selectTime3 * 60 * 60 * 1000 : this.req3.startTime,
      topicName: this.hisTopicName
    }, (data) => {
      if (data && data.constructor === Array) {
        this.historyDate = [];
        this.historyData = [];
        this.histData = data;
        data.map((item, index) => {
            item.dataList.map((item2, index2) => {
              // this.message.error(item2.value);
              // this.message.error(parseFloat(item2.value).toFixed(2));
              // this.historyDate.push(formatDate(new Date(item2.time), 'yyyy-MM-dd', 'cn'));
              this.historyDate.push(this.format(item2.time, 'yyyy-MM-dd hh:mm:ss'));
              this.historyData.push(parseFloat(item2.value).toFixed(2));

            });
        });
        this.chartOption = {
          tooltip: {
            trigger: 'axis',
            position: (pt) => {
              return [pt[0], '10%'];
            },
            formatter: (a) => {
              // console.log(a);
              return a[0].value + this.hisDevInfo.unit;
            }
            // '{c}' + this.hisDevInfo.unit
          },
          title: {
            left: 'center',
            text: '历史数据图',
          },
          toolbox: {
            feature: {
              dataZoom: {
                yAxisIndex: 'none'
              },
              restore: {},
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: this.historyDate,
          },
          yAxis: {
            type: 'value',
            boundaryGap: [0, '100%'],

          },
          dataZoom: [{
            type: 'inside',
            start: 0,
            end: 10
          }, {
            start: 0,
            end: 10,
            handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.' +
            '3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
            handleSize: '80%',
            handleStyle: {
              color: '#fff',
              shadowBlur: 3,
              shadowColor: 'rgba(0, 0, 0, 0.6)',
              shadowOffsetX: 2,
              shadowOffsetY: 2
            }
          }],
          series: [
            {
              name: '数据',
              type: 'line',
              smooth: true,
              symbol: 'none',
              sampling: 'average',
              itemStyle: {
                color: 'rgb(0, 86, 145)'
              },
              data: this.historyData
            }
          ]
        };
      } else {
        this.message.error(data && data.message ? data.message : '获取数据失败');
      }
      this.isLoading = false;
    }]);
  }

  toDetail(one) {
    this.router.navigate(['/app/deviceCenter/sensorMgr/sensorDetail/sensorTopicWay'],
      {queryParams: {deviceNo: one.deviceNo, topicName: one.topicName, sn: this.sn, type: this.type , companyNo: this.companyNo}});
  }

  onSearch() {
    this.getHistoryData();
  }

  onChangePolicy() {
    if (this.policyList && this.req.retentionPolicyNo) {
      const currPolicy = this.policyList.filter(item => item.opValue === this.req.retentionPolicyNo);
      if (currPolicy) {
        this.req.duration = currPolicy[0].duration;
      }
    }
  }

  close() {
  }

  changeModel() {
    this.isMC = true;
    this.req5 = {
      deviceNo: this.deviceNo
    };
    this.try = {trans: {topicModelNo: 'topicModel'}, field: ['topicModelNo']};
  }

  add() {
    this.isUpdate = false;
    this.req = {};
    this.req.deviceNo = this.deviceNo;
    this.req.frequency = 0;
    this.isUC = true;
    this.try = {
      trans: {dataType: 'dataType', frequency: 'frequency', interval: 'interval',
        topicName: 'topicName', topicType: 'topicType', dataInterval: 'dataSaveInTime'},
      field: ['dataType', 'deviceNo', 'frequency', 'interval', 'topicName', 'topicType'],
      num: ['interval', 'dataInterval']
    };
  }

  update(one) {
    this.req = {...one};
    this.isUpdate = true;
    this.req.frequency = 0;
    this.req.oldTopicName = one.topicName;
    if (this.policyList && one.retentionPolicyNo) {
      const currPolicy = this.policyList.filter(item => item.opValue === one.retentionPolicyNo);
      if (currPolicy) {
        this.req.duration = currPolicy[0].duration;
      }
    }

    this.isUC = true;
    this.try = {
      trans: {dataType: 'dataType', frequency: 'frequency', interval: 'interval',
        topicName: 'topicName', topicType: 'topicType', dataInterval: 'dataSaveInTime'},
      field: ['dataType', 'deviceNo', 'frequency', 'interval', 'topicName', 'topicType', 'oldTopicName'],
      num: ['interval', 'dataInterval']
    };
  }

  cancel() {
    this.isUC = false;
    this.isMC = false;
    this.isUC2 = false;
    this.isToSee = false;
    this.isSaveOther = false;
  }

  save(v) {
    this.isLoading = true;
    if (!this.ck.formCheck(this[v], this.try)) {
      this.isLoading = false;
      return;
    }
    if (this.isUC && this.isUpdate) {
      this.api.topic.updateTopic([this.req, this.next]);
    } else if (this.isUC && !this.isUpdate) {
      this.api.topic.addTopic([this.req, this.next]);
    } else if (this.isMC) {
      this.api.device.deploySensor([this.req5, this.next]);
    } else if (this.isUC2 && this.isUpdate) {
      this.req2.deviceNo = this.deviceNo;
      this.api.probes.updateProbes([this.req2, this.next]);
    } else if (this.isUC2 && !this.isUpdate) {
      this.req2.deviceNo = this.deviceNo;
      this.api.probes.addProbes([this.req2, this.next]);
    } else if (this.isSaveOther) {
      this.api.topic.saveAsTopicModel([this.req4, this.next]);
    }
  }

  deleteConfirm(one) {
    this.modalService.confirm({
      nzTitle: '<i> </i>',
      nzContent: '<b>' + this.translate.instant('confirmDeleteThisRecord') + '</b>',
      nzOnOk: () => this.delete(one)
    });
  }

  delete(one) {
    this.isLoading = true;
    this.api.topic.delTopic([{deviceNo: one.deviceNo, topicName: one.topicName}, this.next]);
  }


  add2() {
    this.isUpdate = false;
    this.req2 = {};
    this.req2.deviceNo = this.deviceNo;
    this.isUC2 = true;
    this.try = {field: ['deviceNo', 'topicName', 'name', 'alias', 'description', 'type', 'unit', 'isAlarm']};
  }

  update2(one) {
    this.req2 = {...one};
    this.req2.type = one.pType;
    this.isUpdate = true;
    this.req2.oldName = one.name;
    this.isUC2 = true;
    this.try = {field: ['deviceNo', 'oldName', 'topicName', 'name', 'alias', 'description', 'type', 'unit', 'isAlarm']};
  }

  deleteConfirm2(one) {
    this.modalService.confirm({
      nzTitle: '<i> </i>',
      nzContent: '<b>' + this.translate.instant('confirmDeleteThisRecord') + '</b>',
      nzOnOk: () => this.delete2(one)
    });
  }

  delete2(one) {
    this.isLoading = true;
    this.api.probes.delAProbes([{deviceNo: one.deviceNo, topicName: one.topicName, name: one.name}, this.next]);
  }

  toSee(value) {
    this.isToSee = true;
    this.ToSeeList = value;
  }

  OnReqHistroyChart(index) {
    this.isClose = true;
    this.hisDevInfo = this.actualData[index];
  }

  onSearchToSee() {
    const req = {
      alias: this.ToSeeList.alias,
      deviceList: [
        {
          sn: this.ToSeeList.SN,
          type: this.ToSeeList.type
        }
      ],
      endTime: this.selectTime3 ? new Date().getTime() : this.req3.startTime,
      offset: 0 - new Date().getTimezoneOffset() / 60,
      startTime: this.selectTime3 ? new Date().getTime() - this.selectTime3 * 60 * 60 * 1000 : this.req3.startTime,
      // topicName: 'string'
    };
    this.option1 = {
      grid: {
        top: '5%'
      },
      xAxis: {
        type: 'category',
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
      },
      yAxis: {
        type: 'value'
      },
      series: [{
        data: [820, 932, 901, 934, 1290, 1330, 1320],
        type: 'line',
        smooth: true
      }]
    };
    this.api.data.getHisData([req, (data) => {
      if (data && data.constructor === Array) {
        this.toSeeData = data;
      } else {
        this.message.error(data && data.message ? data.message : '获取数据失败');
      }
    }]);
  }

  saveOther() {
    this.isSaveOther = true;
    this.req4 = {
      companyNo: this.companyNo,
      deviceNo: this.deviceNo,
      newTopicModelName: ''
    };
    this.try = {
      trans: {companyNo: 'selectCompany', newTopicModelName: 'moduleName'},
      field: ['companyNo', 'deviceNo', 'newTopicModelName']};
  }

  refresh() {
    this.getCurrent();
  }

  updateTopicSelection() {
    this.topicOption = [];
    if (this.deviceData) {
      this.deviceData.forEach(one => {
        if (one.topicType !== 4) {
          this.topicOption.push(one);
        }
      });
    }
  }
}
