import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../service/api';
import {CheckService} from '../../service/check';
import {NzMessageService, NzModalService} from 'ng-zorro-antd';
import {TranslateService} from '@ngx-translate/core';
import {fadeIn} from '../../animation/fade-in';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  animations: [fadeIn],
  styles: [`
    :host::ng-deep .cdk-overlay-pane {
      height: 300px;
      overflow-y: auto;
    }
  `]
})
export class UserComponent implements OnInit {

  req: any = {};
  data: any = [];
  isLoading = false;
  isVisible = false;
  isUpdate = false;
  userName = '';
  companys = [];
  roles = [];

  constructor(private api: ApiService, private message: NzMessageService, private translate: TranslateService,
              private modalService: NzModalService, private ck: CheckService) {
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.getData();
    this.getCompanys();
    this.getRoles();
  }

  getCompanys() {
    this.isLoading = true;
    this.api.company.getAll(['', (data) => {
      if (data.constructor === Array) {
        this.companys = data;
      } else {
        this.message.error(data && data.message ? data.message : '获取数据失败');
      }
      this.isLoading = false;
    }]);
  }

  getRoles() {
    this.isLoading = true;
    this.api.role.getAll(['', (data) => {
      if (data.constructor === Array) {
        this.roles = data;
      } else {
        this.message.error(data && data.message ? data.message : '获取数据失败');
      }
      this.isLoading = false;
    }]);
  }

  next = (data) => {
    if (data && data.constructor === Array) {
      this.data = data;
    } else if (data && data.code === 1) {
      this.message.success(data.message ? data.message : '操作成功');
      this.init();
      this.isVisible = false;
    } else if (data && data.code === 0) {
      this.message.error(data.message ? data.message : '操作失败');
    } else {
      this.message.error(data && data.message ? data.message : '获取数据失败');
    }
    this.isLoading = false;
  }

  getData() {
    this.isLoading = true;
    this.api.user.getAll(['', this.next]);
  }

  onSearch() {
    this.isLoading = true;
    this.api.user.getByUserNameForLike([{userName: this.userName}, this.next]);
  }

  onGetAll() {
    this.userName = '';
    this.getData();
  }

  add() {
    this.isUpdate = false;
    this.req = {};
    this.isVisible = true;
  }

  edit(one) {
    this.isUpdate = true;
    this.req = {...one};
    this.isVisible = true;
  }

  save() {
    this.isLoading = true;
    if (this.isUpdate) {
      this.api.user.updateUser([this.req, this.next]);
    } else {
      this.api.user.addUser([this.req, this.next]);
    }
  }

  cancel() {
    this.isVisible = false;
  }

  deleteConfirm(one) {
    this.modalService.confirm({
      nzTitle  : '<i></i>',
      nzContent: '<b>' + this.translate.instant('confirmDeleteThisRecord') + '</b>',
      nzOnOk   : () => this.delete(one)
    });
  }

  delete(one) {
    this.api.user.deleteUser([{userNo: one}, this.next]);
  }
}
